.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
}
.modal-dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}
.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e7eaf0;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1050;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.4;
}
.modal-header {
  align-items: center;
  border-bottom: 1px solid #e7eaf0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1.5rem;
}
.modal-header .btn-close {
  margin: -0.75rem -0.75rem -0.75rem auto;
  padding: 0.75rem;
}
.modal-title {
  line-height: 1.625;
  margin-bottom: 0;
}
.modal-body {
  flex: 1 1 auto;
  padding: 1.5rem;
  position: relative;
}
.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc(0.75rem - 1px);
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-top: 1px solid #e7eaf0;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1rem;
}
.modal-footer > * {
  margin: 0.5rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.modal-content {
  border: 0;
  border-radius: 0.75rem;
}
.modal-fluid .modal-dialog {
  margin-bottom: 0;
  margin-top: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}
.modal-open {
  max-height: 100vh;
}
.modal-dialog-aside {
  margin: 0;
  position: absolute;
}
.modal-primary .modal-title {
  color: #fff;
}
.modal-primary .modal-footer,
.modal-primary .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-primary .modal-content {
  background-color: #13a5a5;
  color: #fff;
}
.modal-primary .close > span:not(.sr-only),
.modal-primary .modal-content .heading {
  color: #fff;
}
.modal-secondary .modal-title {
  color: #000;
}
.modal-secondary .modal-footer,
.modal-secondary .modal-header {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-secondary .modal-content {
  background-color: #cfd6df;
  color: #000;
}
.modal-secondary .modal-content .heading {
  color: #000;
}
.modal-secondary .close > span:not(.sr-only),
.modal-tertiary .modal-title {
  color: #fff;
}
.modal-tertiary .modal-footer,
.modal-tertiary .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-tertiary .modal-content {
  background-color: #ff579a;
  color: #fff;
}
.modal-success .modal-title,
.modal-tertiary .close > span:not(.sr-only),
.modal-tertiary .modal-content .heading {
  color: #fff;
}
.modal-success .modal-footer,
.modal-success .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-success .modal-content {
  background-color: #0c8;
  color: #fff;
}
.modal-success .close > span:not(.sr-only),
.modal-success .modal-content .heading {
  color: #fff;
}
.modal-info .modal-title {
  color: #000;
}
.modal-info .modal-footer,
.modal-info .modal-header {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-info .modal-content {
  background-color: #00d4ff;
  color: #000;
}
.modal-info .modal-content .heading {
  color: #000;
}
.modal-info .close > span:not(.sr-only),
.modal-warning .modal-title {
  color: #fff;
}
.modal-warning .modal-footer,
.modal-warning .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-warning .modal-content {
  background-color: #ff8c00;
  color: #fff;
}
.modal-danger .modal-title,
.modal-warning .close > span:not(.sr-only),
.modal-warning .modal-content .heading {
  color: #fff;
}
.modal-danger .modal-footer,
.modal-danger .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-danger .modal-content {
  background-color: #f36;
  color: #fff;
}
.modal-danger .close > span:not(.sr-only),
.modal-danger .modal-content .heading {
  color: #fff;
}
.modal-white .modal-title {
  color: #000;
}
.modal-white .modal-footer,
.modal-white .modal-header {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-white .modal-content {
  background-color: #fff;
  color: #000;
}
.modal-white .modal-content .heading {
  color: #000;
}
.modal-white .close > span:not(.sr-only) {
  color: #fff;
}
.modal-light .modal-title {
  color: #000;
}
.modal-light .modal-footer,
.modal-light .modal-header {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-light .modal-content {
  background-color: #fff;
  color: #000;
}
.modal-light .modal-content .heading {
  color: #000;
}
.modal-dark .modal-title,
.modal-light .close > span:not(.sr-only) {
  color: #fff;
}
.modal-dark .modal-footer,
.modal-dark .modal-header {
  border-color: hsla(0, 0%, 100%, 0.075);
}
.modal-dark .modal-content {
  background-color: #16192c;
  color: #fff;
}
.modal-dark .close > span:not(.sr-only),
.modal-dark .modal-content .heading {
  color: #fff;
}
.modal-vertical {
  height: calc(100% - 0.5rem);
  margin: 0.5rem 0;
  max-width: 350px;
}
.modal-vertical.modal-lg {
  max-width: 450px;
}
.modal-vertical .modal-content {
  border-radius: 0.75rem 0 0 0.75rem;
  border-width: 0;
  height: inherit;
}
.modal-vertical .modal-body {
  height: inherit;
  overflow-y: auto;
}
.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%);
}
.modal.show .modal-dialog-vertical {
  transform: translateX(0);
}
.modal.fixed-right {
  padding-right: 0 !important;
}
.modal.fixed-right .modal-vertical {
  margin-left: auto;
}
.modal.fixed-right.fade .modal-vertical {
  transform: translateX(100%);
}
.modal.fixed-right.show .modal-vertical {
  transform: translateX(0);
}
.offcanvas {
  background-clip: padding-box;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;
  z-index: 1045;
}
