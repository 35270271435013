/* Notification */
.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon {
  font-size: 22px;
  vertical-align: middle;
  line-height: 70px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 6px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small {
  display: block;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.notification-list .notify-item .user-msg {
  margin-left: 45px;
  white-space: normal;
  line-height: 16px;
}
.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.profile-dropdown {
  min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  padding: calc(31px / 2) 20px calc(31px / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px;
}
.nav-user .account-user-avatar {
  position: absolute;
  top: calc(38px / 2);
  left: 15px;
}
.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}
.nav-user .account-position {
  display: block;
  font-size: 12px;
  margin-top: -3px;
}
.nav-user .account-user-name {
  display: block;
  font-weight: 600;
}
