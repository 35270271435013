#despinMap {
  width: 100%;
  height: 90vh;
  z-index: 1;
  transition: 0.5s ease-in-out;
}

.btn-close {
  color: white !important;
}

.btn-map-control {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #757575;
  border: 0;
}

.btn-map-control i {
  color: black;
  font-size: 1rem;
}

.btn-pinned {
  position: absolute;
  right: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  top: -15px;
  justify-content: center;
  align-items: center;
}

.ck-content {
  height: 250px;
}

.despin-progress-toast {
  position: fixed;
  top: 70px;
  right: 0;
  z-index: 9999999;
}

.despin-progress-toast .btn-close {
  display: none;
}

.despin-loading .modal-content {
  width: 130px !important;
  left: 80px !important;
}

.despin-btn-table {
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px !important;
}

.despin-btn-table i {
  position: relative;
  top: 1.5px;
  right: 1px !important;
  display: block !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
}

.despin-btn-table i {
  font-size: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-search .form-control {
  padding-left: 2.375rem;
}

.form-search i::before {
  position: absolute;
  z-index: 2;
  display: block;
  font-size: 1rem;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.275rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.sr-only {
  display: none;
}
