.file-uploader {
  margin-bottom: 0 !important;
  border: 1px dashed #d5d2dc !important;
  border-radius: 0.75rem !important;
  cursor: pointer !important;
  font-family: "Poppins", sans-serif !important;
}

.file-uploader-avatar {
  width: 175px !important;
  height: 175px !important;
  border-radius: 100% !important;
}

.filepond--root .filepond--drop-label {
  min-height: 10rem !important;
}

.filepond--root .filepond--drop-label > label {
  cursor: pointer !important;
}

.filepond--panel,
.filepond--panel-root {
  background-color: transparent !important;
}

.filepond--image-preview {
  background-color: #1f1b2d !important;
}

.filepond--credits {
  display: none !important;
}

[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: #f23c49 !important;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: #07c98b !important;
}

@media (max-width: 680px) {
  .file-uploader-grid .filepond--item {
    width: calc(50% - 0.5em) !important;
  }
}
