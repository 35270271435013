.icon-shape {
  align-items: center;
  border-radius: 0.375rem;
  display: inline-flex;
  height: 3rem;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 3rem;
}
.icon-shape.icon-xl {
  height: 5rem;
  width: 5rem;
}
.icon-shape.icon-lg {
  height: 4rem;
  width: 4rem;
}
.icon-shape.icon-sm {
  height: 2rem;
  width: 2rem;
}
.icon-shape.icon-xs {
  height: 1.25rem;
  width: 1.25rem;
}
