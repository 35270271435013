.btn-geeks {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  color: #525f7f;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  padding: 0.75rem 1.25rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn-geeks {
    transition: none;
  }
}
.btn-geeks:hover {
  color: #525f7f;
}
.btn-geeks-check:focus + .btn-geeks,
.btn-geeks:focus {
  box-shadow: 0 0 0 3px rgba(19, 165, 165, 0.25);
  outline: 0;
}
.btn-geeks-check:active + .btn-geeks,
.btn-geeks-check:checked + .btn-geeks,
.btn-geeks.active,
.btn-geeks:active {
  box-shadow: 0 0 0 0 transparent;
}
.btn-geeks-check:active + .btn-geeks:focus,
.btn-geeks-check:checked + .btn-geeks:focus,
.btn-geeks.active:focus,
.btn-geeks:active:focus {
  box-shadow: 0 0 0 3px rgba(19, 165, 165, 0.25), 0 0 0 0 transparent;
}
.btn-geeks.disabled,
.btn-geeks:disabled,
fieldset:disabled .btn-geeks {
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none;
}
.btn-geeks-primary {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-primary,
.btn-geeks-primary:focus,
.btn-geeks-primary:hover {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-primary,
.btn-geeks-primary:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(116, 120, 247, 0.5);
}
.btn-geeks-check:active + .btn-geeks-primary,
.btn-geeks-check:checked + .btn-geeks-primary,
.btn-geeks-primary.active,
.btn-geeks-primary:active,
.show > .btn-geeks-primary.dropdown-toggle {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-primary:focus,
.btn-geeks-check:checked + .btn-geeks-primary:focus,
.btn-geeks-primary.active:focus,
.btn-geeks-primary:active:focus,
.show > .btn-geeks-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px #4DBFA6;
}
.btn-geeks-primary.disabled,
.btn-geeks-primary:disabled {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  color: #fff;
}
.btn-geeks-secondary {
  background-color: #cfd6df;
  border-color: #cfd6df;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-secondary,
.btn-geeks-secondary:focus,
.btn-geeks-secondary:hover {
  background-color: #d9dee5;
  border-color: #d4dae2;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-secondary,
.btn-geeks-secondary:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(176, 182, 190, 0.5);
}
.btn-geeks-check:active + .btn-geeks-secondary,
.btn-geeks-check:checked + .btn-geeks-secondary,
.btn-geeks-secondary.active,
.btn-geeks-secondary:active,
.show > .btn-geeks-secondary.dropdown-toggle {
  background-color: #d9dee5;
  border-color: #d4dae2;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-secondary:focus,
.btn-geeks-check:checked + .btn-geeks-secondary:focus,
.btn-geeks-secondary.active:focus,
.btn-geeks-secondary:active:focus,
.show > .btn-geeks-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(176, 182, 190, 0.5);
}
.btn-geeks-secondary.disabled,
.btn-geeks-secondary:disabled {
  background-color: #cfd6df;
  border-color: #cfd6df;
  color: #000;
}
.btn-geeks-tertiary {
  background-color: #ff579a;
  border-color: #ff579a;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-tertiary,
.btn-geeks-tertiary:focus,
.btn-geeks-tertiary:hover {
  background-color: #cc467b;
  border-color: #cc467b;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-tertiary,
.btn-geeks-tertiary:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 112, 169, 0.5);
}
.btn-geeks-check:active + .btn-geeks-tertiary,
.btn-geeks-check:checked + .btn-geeks-tertiary,
.btn-geeks-tertiary.active,
.btn-geeks-tertiary:active,
.show > .btn-geeks-tertiary.dropdown-toggle {
  background-color: #cc467b;
  border-color: #bf4174;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-tertiary:focus,
.btn-geeks-check:checked + .btn-geeks-tertiary:focus,
.btn-geeks-tertiary.active:focus,
.btn-geeks-tertiary:active:focus,
.show > .btn-geeks-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 112, 169, 0.5);
}
.btn-geeks-tertiary.disabled,
.btn-geeks-tertiary:disabled {
  background-color: #ff579a;
  border-color: #ff579a;
  color: #fff;
}
.btn-geeks-success {
  background-color: #0c8;
  border-color: #0c8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-success,
.btn-geeks-success:focus,
.btn-geeks-success:hover {
  background-color: #00a36d;
  border-color: #00a36d;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-success,
.btn-geeks-success:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(38, 212, 154, 0.5);
}
.btn-geeks-check:active + .btn-geeks-success,
.btn-geeks-check:checked + .btn-geeks-success,
.btn-geeks-success.active,
.btn-geeks-success:active,
.show > .btn-geeks-success.dropdown-toggle {
  background-color: #00a36d;
  border-color: #096;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-success:focus,
.btn-geeks-check:checked + .btn-geeks-success:focus,
.btn-geeks-success.active:focus,
.btn-geeks-success:active:focus,
.show > .btn-geeks-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(38, 212, 154, 0.5);
}
.btn-geeks-success.disabled,
.btn-geeks-success:disabled {
  background-color: #0c8;
  border-color: #0c8;
  color: #fff;
}
.btn-geeks-info {
  background-color: #00d4ff;
  border-color: #00d4ff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-info,
.btn-geeks-info:focus,
.btn-geeks-info:hover {
  background-color: #3df;
  border-color: #1ad8ff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-info,
.btn-geeks-info:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 180, 217, 0.5);
}
.btn-geeks-check:active + .btn-geeks-info,
.btn-geeks-check:checked + .btn-geeks-info,
.btn-geeks-info.active,
.btn-geeks-info:active,
.show > .btn-geeks-info.dropdown-toggle {
  background-color: #3df;
  border-color: #1ad8ff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-info:focus,
.btn-geeks-check:checked + .btn-geeks-info:focus,
.btn-geeks-info.active:focus,
.btn-geeks-info:active:focus,
.show > .btn-geeks-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 180, 217, 0.5);
}
.btn-geeks-info.disabled,
.btn-geeks-info:disabled {
  background-color: #00d4ff;
  border-color: #00d4ff;
  color: #000;
}
.btn-geeks-warning {
  background-color: #ff8c00;
  border-color: #ff8c00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-warning,
.btn-geeks-warning:focus,
.btn-geeks-warning:hover {
  background-color: #cc7000;
  border-color: #cc7000;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-warning,
.btn-geeks-warning:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 157, 38, 0.5);
}
.btn-geeks-check:active + .btn-geeks-warning,
.btn-geeks-check:checked + .btn-geeks-warning,
.btn-geeks-warning.active,
.btn-geeks-warning:active,
.show > .btn-geeks-warning.dropdown-toggle {
  background-color: #cc7000;
  border-color: #bf6900;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-warning:focus,
.btn-geeks-check:checked + .btn-geeks-warning:focus,
.btn-geeks-warning.active:focus,
.btn-geeks-warning:active:focus,
.show > .btn-geeks-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 157, 38, 0.5);
}
.btn-geeks-warning.disabled,
.btn-geeks-warning:disabled {
  background-color: #ff8c00;
  border-color: #ff8c00;
  color: #fff;
}
.btn-geeks-danger {
  background-color: #f36;
  border-color: #f36;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-danger,
.btn-geeks-danger:focus,
.btn-geeks-danger:hover {
  background-color: #cc2952;
  border-color: #cc2952;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-danger,
.btn-geeks-danger:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 82, 125, 0.5);
}
.btn-geeks-check:active + .btn-geeks-danger,
.btn-geeks-check:checked + .btn-geeks-danger,
.btn-geeks-danger.active,
.btn-geeks-danger:active,
.show > .btn-geeks-danger.dropdown-toggle {
  background-color: #cc2952;
  border-color: #bf264d;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-danger:focus,
.btn-geeks-check:checked + .btn-geeks-danger:focus,
.btn-geeks-danger.active:focus,
.btn-geeks-danger:active:focus,
.show > .btn-geeks-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 82, 125, 0.5);
}
.btn-geeks-danger.disabled,
.btn-geeks-danger:disabled {
  background-color: #f36;
  border-color: #f36;
  color: #fff;
}
.btn-geeks-white {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-geeks-check:focus + .btn-geeks-white,
.btn-geeks-white,
.btn-geeks-white:focus,
.btn-geeks-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-white,
.btn-geeks-white:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}
.btn-geeks-check:active + .btn-geeks-white,
.btn-geeks-check:checked + .btn-geeks-white,
.btn-geeks-white.active,
.btn-geeks-white:active,
.show > .btn-geeks-white.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-white:focus,
.btn-geeks-check:checked + .btn-geeks-white:focus,
.btn-geeks-white.active:focus,
.btn-geeks-white:active:focus,
.show > .btn-geeks-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}
.btn-geeks-light,
.btn-geeks-white.disabled,
.btn-geeks-white:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-light {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-geeks-check:focus + .btn-geeks-light,
.btn-geeks-light:focus,
.btn-geeks-light:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-light,
.btn-geeks-light:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}
.btn-geeks-check:active + .btn-geeks-light,
.btn-geeks-check:checked + .btn-geeks-light,
.btn-geeks-light.active,
.btn-geeks-light:active,
.show > .btn-geeks-light.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-light:focus,
.btn-geeks-check:checked + .btn-geeks-light:focus,
.btn-geeks-light.active:focus,
.btn-geeks-light:active:focus,
.show > .btn-geeks-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}
.btn-geeks-light.disabled,
.btn-geeks-light:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-dark {
  background-color: #16192c;
  border-color: #16192c;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-dark,
.btn-geeks-dark:focus,
.btn-geeks-dark:hover {
  background-color: #121423;
  border-color: #121423;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-dark,
.btn-geeks-dark:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(57, 60, 76, 0.5);
}
.btn-geeks-check:active + .btn-geeks-dark,
.btn-geeks-check:checked + .btn-geeks-dark,
.btn-geeks-dark.active,
.btn-geeks-dark:active,
.show > .btn-geeks-dark.dropdown-toggle {
  background-color: #121423;
  border-color: #111321;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-dark:focus,
.btn-geeks-check:checked + .btn-geeks-dark:focus,
.btn-geeks-dark.active:focus,
.btn-geeks-dark:active:focus,
.show > .btn-geeks-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(57, 60, 76, 0.5);
}
.btn-geeks-dark.disabled,
.btn-geeks-dark:disabled {
  background-color: #16192c;
  border-color: #16192c;
  color: #fff;
}
.btn-geeks-outline-primary {
  border-color: #4DBFA6;
  color: #4DBFA6;
}
.btn-geeks-outline-primary:hover {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-primary,
.btn-geeks-outline-primary:focus {
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-primary,
.btn-geeks-check:checked + .btn-geeks-outline-primary,
.btn-geeks-outline-primary.active,
.btn-geeks-outline-primary.dropdown-toggle.show,
.btn-geeks-outline-primary:active {
  background-color: #4DBFA6;
  border-color: #4DBFA6;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-primary:focus,
.btn-geeks-check:checked + .btn-geeks-outline-primary:focus,
.btn-geeks-outline-primary.active:focus,
.btn-geeks-outline-primary.dropdown-toggle.show:focus,
.btn-geeks-outline-primary:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(92, 96, 245, 0.5);
}
.btn-geeks-outline-primary.disabled,
.btn-geeks-outline-primary:disabled {
  background-color: transparent;
  color: #4DBFA6;
}
.btn-geeks-outline-secondary {
  border-color: #cfd6df;
  color: #cfd6df;
}
.btn-geeks-outline-secondary:hover {
  background-color: #cfd6df;
  border-color: #cfd6df;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-outline-secondary,
.btn-geeks-outline-secondary:focus {
  box-shadow: 0 0 0 3px rgba(207, 214, 223, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-secondary,
.btn-geeks-check:checked + .btn-geeks-outline-secondary,
.btn-geeks-outline-secondary.active,
.btn-geeks-outline-secondary.dropdown-toggle.show,
.btn-geeks-outline-secondary:active {
  background-color: #cfd6df;
  border-color: #cfd6df;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-outline-secondary:focus,
.btn-geeks-check:checked + .btn-geeks-outline-secondary:focus,
.btn-geeks-outline-secondary.active:focus,
.btn-geeks-outline-secondary.dropdown-toggle.show:focus,
.btn-geeks-outline-secondary:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(207, 214, 223, 0.5);
}
.btn-geeks-outline-secondary.disabled,
.btn-geeks-outline-secondary:disabled {
  background-color: transparent;
  color: #cfd6df;
}
.btn-geeks-outline-tertiary {
  border-color: #ff579a;
  color: #ff579a;
}
.btn-geeks-outline-tertiary:hover {
  background-color: #ff579a;
  border-color: #ff579a;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-tertiary,
.btn-geeks-outline-tertiary:focus {
  box-shadow: 0 0 0 3px rgba(255, 87, 154, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-tertiary,
.btn-geeks-check:checked + .btn-geeks-outline-tertiary,
.btn-geeks-outline-tertiary.active,
.btn-geeks-outline-tertiary.dropdown-toggle.show,
.btn-geeks-outline-tertiary:active {
  background-color: #ff579a;
  border-color: #ff579a;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-tertiary:focus,
.btn-geeks-check:checked + .btn-geeks-outline-tertiary:focus,
.btn-geeks-outline-tertiary.active:focus,
.btn-geeks-outline-tertiary.dropdown-toggle.show:focus,
.btn-geeks-outline-tertiary:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 87, 154, 0.5);
}
.btn-geeks-outline-tertiary.disabled,
.btn-geeks-outline-tertiary:disabled {
  background-color: transparent;
  color: #ff579a;
}
.btn-geeks-outline-success {
  border-color: #0c8;
  color: #0c8;
}
.btn-geeks-outline-success:hover {
  background-color: #0c8;
  border-color: #0c8;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-success,
.btn-geeks-outline-success:focus {
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-success,
.btn-geeks-check:checked + .btn-geeks-outline-success,
.btn-geeks-outline-success.active,
.btn-geeks-outline-success.dropdown-toggle.show,
.btn-geeks-outline-success:active {
  background-color: #0c8;
  border-color: #0c8;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-success:focus,
.btn-geeks-check:checked + .btn-geeks-outline-success:focus,
.btn-geeks-outline-success.active:focus,
.btn-geeks-outline-success.dropdown-toggle.show:focus,
.btn-geeks-outline-success:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 204, 136, 0.5);
}
.btn-geeks-outline-success.disabled,
.btn-geeks-outline-success:disabled {
  background-color: transparent;
  color: #0c8;
}
.btn-geeks-outline-info {
  border-color: #00d4ff;
  color: #00d4ff;
}
.btn-geeks-outline-info:hover {
  background-color: #00d4ff;
  border-color: #00d4ff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-outline-info,
.btn-geeks-outline-info:focus {
  box-shadow: 0 0 0 3px rgba(0, 212, 255, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-info,
.btn-geeks-check:checked + .btn-geeks-outline-info,
.btn-geeks-outline-info.active,
.btn-geeks-outline-info.dropdown-toggle.show,
.btn-geeks-outline-info:active {
  background-color: #00d4ff;
  border-color: #00d4ff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-outline-info:focus,
.btn-geeks-check:checked + .btn-geeks-outline-info:focus,
.btn-geeks-outline-info.active:focus,
.btn-geeks-outline-info.dropdown-toggle.show:focus,
.btn-geeks-outline-info:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 212, 255, 0.5);
}
.btn-geeks-outline-info.disabled,
.btn-geeks-outline-info:disabled {
  background-color: transparent;
  color: #00d4ff;
}
.btn-geeks-outline-warning {
  border-color: #ff8c00;
  color: #ff8c00;
}
.btn-geeks-outline-warning:hover {
  background-color: #ff8c00;
  border-color: #ff8c00;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-warning,
.btn-geeks-outline-warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 140, 0, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-warning,
.btn-geeks-check:checked + .btn-geeks-outline-warning,
.btn-geeks-outline-warning.active,
.btn-geeks-outline-warning.dropdown-toggle.show,
.btn-geeks-outline-warning:active {
  background-color: #ff8c00;
  border-color: #ff8c00;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-warning:focus,
.btn-geeks-check:checked + .btn-geeks-outline-warning:focus,
.btn-geeks-outline-warning.active:focus,
.btn-geeks-outline-warning.dropdown-toggle.show:focus,
.btn-geeks-outline-warning:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 140, 0, 0.5);
}
.btn-geeks-outline-warning.disabled,
.btn-geeks-outline-warning:disabled {
  background-color: transparent;
  color: #ff8c00;
}
.btn-geeks-outline-danger {
  border-color: #f36;
  color: #f36;
}
.btn-geeks-outline-danger:hover {
  background-color: #f36;
  border-color: #f36;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-danger,
.btn-geeks-outline-danger:focus {
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-danger,
.btn-geeks-check:checked + .btn-geeks-outline-danger,
.btn-geeks-outline-danger.active,
.btn-geeks-outline-danger.dropdown-toggle.show,
.btn-geeks-outline-danger:active {
  background-color: #f36;
  border-color: #f36;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-danger:focus,
.btn-geeks-check:checked + .btn-geeks-outline-danger:focus,
.btn-geeks-outline-danger.active:focus,
.btn-geeks-outline-danger.dropdown-toggle.show:focus,
.btn-geeks-outline-danger:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 51, 102, 0.5);
}
.btn-geeks-outline-danger.disabled,
.btn-geeks-outline-danger:disabled {
  background-color: transparent;
  color: #f36;
}
.btn-geeks-outline-white {
  border-color: #fff;
  color: #fff;
}
.btn-geeks-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-outline-white,
.btn-geeks-outline-white:focus {
  box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-white,
.btn-geeks-check:checked + .btn-geeks-outline-white,
.btn-geeks-outline-white.active,
.btn-geeks-outline-white.dropdown-toggle.show,
.btn-geeks-outline-white:active {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-outline-white:focus,
.btn-geeks-check:checked + .btn-geeks-outline-white:focus,
.btn-geeks-outline-white.active:focus,
.btn-geeks-outline-white.dropdown-toggle.show:focus,
.btn-geeks-outline-white:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}
.btn-geeks-outline-white.disabled,
.btn-geeks-outline-white:disabled {
  background-color: transparent;
  color: #fff;
}
.btn-geeks-outline-light {
  border-color: #fff;
  color: #fff;
}
.btn-geeks-outline-light:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:focus + .btn-geeks-outline-light,
.btn-geeks-outline-light:focus {
  box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-light,
.btn-geeks-check:checked + .btn-geeks-outline-light,
.btn-geeks-outline-light.active,
.btn-geeks-outline-light.dropdown-toggle.show,
.btn-geeks-outline-light:active {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-outline-light:focus,
.btn-geeks-check:checked + .btn-geeks-outline-light:focus,
.btn-geeks-outline-light.active:focus,
.btn-geeks-outline-light.dropdown-toggle.show:focus,
.btn-geeks-outline-light:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}
.btn-geeks-outline-light.disabled,
.btn-geeks-outline-light:disabled {
  background-color: transparent;
  color: #fff;
}
.btn-geeks-outline-dark {
  border-color: #16192c;
  color: #16192c;
}
.btn-geeks-outline-dark:hover {
  background-color: #16192c;
  border-color: #16192c;
  color: #fff;
}
.btn-geeks-check:focus + .btn-geeks-outline-dark,
.btn-geeks-outline-dark:focus {
  box-shadow: 0 0 0 3px rgba(22, 25, 44, 0.5);
}
.btn-geeks-check:active + .btn-geeks-outline-dark,
.btn-geeks-check:checked + .btn-geeks-outline-dark,
.btn-geeks-outline-dark.active,
.btn-geeks-outline-dark.dropdown-toggle.show,
.btn-geeks-outline-dark:active {
  background-color: #16192c;
  border-color: #16192c;
  color: #fff;
}
.btn-geeks-check:active + .btn-geeks-outline-dark:focus,
.btn-geeks-check:checked + .btn-geeks-outline-dark:focus,
.btn-geeks-outline-dark.active:focus,
.btn-geeks-outline-dark.dropdown-toggle.show:focus,
.btn-geeks-outline-dark:active:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(22, 25, 44, 0.5);
}
.btn-geeks-outline-dark.disabled,
.btn-geeks-outline-dark:disabled {
  background-color: transparent;
  color: #16192c;
}
.btn-geeks-link {
  color: #5c60f5;
  font-weight: 400;
  text-decoration: none;
}
.btn-geeks-link:hover {
  color: #4e52d0;
}
.btn-geeks-link:focus,
.btn-geeks-link:hover {
  text-decoration: none;
}
.btn-geeks-link.disabled,
.btn-geeks-link:disabled {
  color: #6b7b93;
}
.btn-geeks-group-lg > .btn-geeks,
.btn-geeks-lg {
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 1rem 1.5rem;
}
.btn-geeks-group-sm > .btn-geeks,
.btn-geeks-sm {
  border-radius: 0.375rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}
.btn-geeks-neutral {
  background-color: #fff;
  border-color: #e7eaf0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #16192c;
}
.btn-geeks-check:focus + .btn-geeks-neutral,
.btn-geeks-neutral:focus,
.btn-geeks-neutral:hover {
  background-color: #e7eaf0;
  border-color: #e7eaf0;
  color: #16192c;
}
.btn-geeks-check:focus + .btn-geeks-neutral,
.btn-geeks-neutral:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-geeks-check:active + .btn-geeks-neutral,
.btn-geeks-check:checked + .btn-geeks-neutral,
.btn-geeks-neutral.active,
.btn-geeks-neutral:active,
.show > .btn-geeks-neutral.dropdown-toggle {
  background-color: #fff;
  border-color: #e9ecf2;
  color: #000;
}
.btn-geeks-check:active + .btn-geeks-neutral:focus,
.btn-geeks-check:checked + .btn-geeks-neutral:focus,
.btn-geeks-neutral.active:focus,
.btn-geeks-neutral:active:focus,
.show > .btn-geeks-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-geeks-neutral.disabled,
.btn-geeks-neutral:disabled {
  background-color: #fff;
  border-color: #e7eaf0;
  color: #000;
}
.btn-geeks-square {
  align-items: center;
  display: inline-flex;
  height: 2.875rem;
  justify-content: center;
  padding: 0;
  width: 2.875rem;
}
.btn-geeks-square.btn-geeks-xs {
  height: 1.75rem;
  width: 1.75rem;
}
.btn-geeks-group-sm > .btn-geeks-square.btn-geeks,
.btn-geeks-square.btn-geeks-sm {
  height: 2.25rem;
  width: 2.25rem;
}
.btn-geeks-group-lg > .btn-geeks-square.btn-geeks,
.btn-geeks-square.btn-geeks-lg {
  height: 3.5rem;
  width: 3.5rem;
}
.btn-geeks-square.btn-geeks-xl {
  height: 6rem;
  width: 6rem;
}
.btn-geeks-square.btn-geeks-2xl {
  height: 7.5rem;
  width: 7.5rem;
}
.btn-geeks-app {
  background: #000;
  color: #fff;
  padding-left: 4.5rem;
  padding-right: 1.5rem;
  position: relative;
  text-align: left;
}
.btn-geeks-app i,
.btn-geeks-app svg {
  font-size: 2.5rem;
  left: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
.btn-geeks-app .btn-geeks-text {
  display: block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-bottom: 0.125rem;
}
.btn-geeks-app .btn-geeks-brand {
  display: block;
  font-size: 21px;
  line-height: 21px;
  text-align: left;
}
.btn-geeks-app:hover {
  background-color: #5659e4;
  color: #fff;
}
.btn-geeks-animated {
  overflow: hidden;
}
.btn-geeks-animated,
.btn-geeks-animated .btn-geeks-inner-visible {
  position: relative;
}
.btn-geeks-animated .btn-geeks-inner-hidden {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.btn-geeks-animated-x .btn-geeks-inner-visible {
  right: 0;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-geeks-animated-x .btn-geeks-inner-visible {
    transition: none;
  }
}
.btn-geeks-animated-x .btn-geeks-inner-hidden {
  right: -100%;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-geeks-animated-x .btn-geeks-inner-hidden {
    transition: none;
  }
}
.btn-geeks-animated-x:hover .btn-geeks-inner-hidden {
  right: 0;
}
.btn-geeks-animated-x:hover .btn-geeks-inner-visible {
  right: 150%;
}
.btn-geeks-animated-y .btn-geeks-inner-visible {
  top: 0;
  transition: top 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-geeks-animated-y .btn-geeks-inner-visible {
    transition: none;
  }
}
.btn-geeks-animated-y .btn-geeks-inner-hidden {
  left: 0;
  top: -100%;
  transition: top 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn-geeks-animated-y .btn-geeks-inner-hidden {
    transition: none;
  }
}
.btn-geeks-animated-y:hover .btn-geeks-inner-hidden {
  top: 50%;
}
.btn-geeks-animated-y:hover .btn-geeks-inner-visible {
  top: 100px;
}
.btn-geeks-group,
.btn-geeks-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-geeks-group-vertical > .btn-geeks,
.btn-geeks-group > .btn-geeks {
  flex: 1 1 auto;
  position: relative;
}
.btn-geeks-group-vertical > .btn-geeks-check:checked + .btn-geeks,
.btn-geeks-group-vertical > .btn-geeks-check:focus + .btn-geeks,
.btn-geeks-group-vertical > .btn-geeks.active,
.btn-geeks-group-vertical > .btn-geeks:active,
.btn-geeks-group-vertical > .btn-geeks:focus,
.btn-geeks-group-vertical > .btn-geeks:hover,
.btn-geeks-group > .btn-geeks-check:checked + .btn-geeks,
.btn-geeks-group > .btn-geeks-check:focus + .btn-geeks,
.btn-geeks-group > .btn-geeks.active,
.btn-geeks-group > .btn-geeks:active,
.btn-geeks-group > .btn-geeks:focus,
.btn-geeks-group > .btn-geeks:hover {
  z-index: 1;
}
.btn-geeks-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-geeks-toolbar .input-group {
  width: auto;
}
.btn-geeks-group > .btn-geeks-group:not(:first-child),
.btn-geeks-group > .btn-geeks:not(:first-child) {
  margin-left: -1px;
}
.btn-geeks-group > .btn-geeks-group:not(:last-child) > .btn-geeks,
.btn-geeks-group > .btn-geeks:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-geeks-group > .btn-geeks-group:not(:first-child) > .btn-geeks,
.btn-geeks-group > .btn-geeks:nth-child(n + 3),
.btn-geeks-group > :not(.btn-geeks-check) + .btn-geeks {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-toggle-split {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}
.btn-geeks-group-sm > .btn-geeks + .dropdown-toggle-split,
.btn-geeks-sm + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn-geeks-group-lg > .btn-geeks + .dropdown-toggle-split,
.btn-geeks-lg + .dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
.btn-geeks-group.show .dropdown-toggle {
  box-shadow: 0 0 0 0 transparent;
}
.btn-geeks-group.show .dropdown-toggle.btn-geeks-link {
  box-shadow: none;
}
.btn-geeks-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-geeks-group-vertical > .btn-geeks,
.btn-geeks-group-vertical > .btn-geeks-group {
  width: 100%;
}
.btn-geeks-group-vertical > .btn-geeks-group:not(:first-child),
.btn-geeks-group-vertical > .btn-geeks:not(:first-child) {
  margin-top: -1px;
}
.btn-geeks-group-vertical > .btn-geeks-group:not(:last-child) > .btn-geeks,
.btn-geeks-group-vertical > .btn-geeks:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-geeks-group-vertical > .btn-geeks-group:not(:first-child) > .btn-geeks,
.btn-geeks-group-vertical > .btn-geeks ~ .btn-geeks {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
