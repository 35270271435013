@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 3.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #2c8ef8;
  --indigo: #00bfa6;
  --purple: #6b5eae;
  --pink: #ff679b;
  --red: #fa5c7c;
  --orange: #fd7e14;
  --yellow: #ffbc00;
  --green: #0acf97;
  --teal: #02a8b5;
  --cyan: #39afd1;
  --white: #fff;
  --gray: #98a6ad;
  --gray-dark: #343a40;
  --primary: #4DBFA6;
  --secondary: #6c757d;
  --success: #0acf97;
  --info: #39afd1;
  --warning: #ffbc00;
  --danger: #fa5c7c;
  --light: #eef2f7;
  --dark: #313a46;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1367px;
  --font-family-sans-serif: "Poppins", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #4DBFA6;
  text-decoration: none;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 0.9375rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eef2f7;
}

small,
.small {
  font-size: 0.75rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #39afd1;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1367px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1367px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1367px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #6c757d;
}
.table th,
.table td {
  padding: 0.95rem;
  vertical-align: top;
  border-top: 1px solid #eef2f7;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eef2f7;
}
.table tbody + tbody {
  border-top: 2px solid #eef2f7;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-bordered {
  border: 1px solid #eef2f7;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #eef2f7;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f3fa;
}

.table-hover tbody tr:hover {
  color: #6c757d;
  background-color: #f1f3fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8dafc;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b6bbfa;
}

.table-hover .table-primary:hover {
  background-color: #c0c4fa;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c0c4fa;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #baf2e2;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #80e6c9;
}

.table-hover .table-success:hover {
  background-color: #a5eed9;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5eed9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8e9f2;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #98d5e7;
}

.table-hover .table-info:hover {
  background-color: #b3e1ed;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e1ed;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecb8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdc7a;
}

.table-hover .table-warning:hover {
  background-color: #ffe59f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed1da;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fcaabb;
}

.table-hover .table-danger:hover {
  background-color: #fdb8c6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdb8c6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f8fb;
}

.table-hover .table-light:hover {
  background-color: #e8ecf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8ecf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c8cb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #94999f;
}

.table-hover .table-dark:hover {
  background-color: #b8bbbf;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b8bbbf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #98a6ad;
  background-color: #313a46;
  border-color: #414d5d;
}

.table .thead-light th {
  color: #6c757d;
  background-color: #f1f3fa;
  border-color: #eef2f7;
}

.table-dark {
  color: #98a6ad;
  background-color: #313a46;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #414d5d;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1366.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6c757d;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c1c9d0;
  outline: 1px solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.28rem + 1px);
  padding-bottom: calc(0.28rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8725rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #98a6ad;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #0acf97;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(10, 207, 151, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #0acf97;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #0acf97;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #0acf97;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #0acf97;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #0acf97;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #0acf97;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #0acf97;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #18f4b5;
  background-color: #18f4b5;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #0acf97;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #0acf97;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #0acf97;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #fa5c7c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(250, 92, 124, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #fa5c7c;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #fa5c7c;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #fa5c7c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #fa5c7c;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #fa5c7c;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #fa5c7c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #fa5c7c;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fc8da4;
  background-color: #fc8da4;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #fa5c7c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fa5c7c;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fa5c7c;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.15rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #6c757d;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
  box-shadow: 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  color: #fff;
  opacity: 0.9;
  background-color: #4DBFA6;
  border-color: #4DBFA6;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  opacity: 0.9;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  opacity: 0.9;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-success:hover {
  color: #fff;
  background-color: #08ab7c;
  border-color: #089e74;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #08ab7c;
  border-color: #089e74;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #089e74;
  border-color: #07926b;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:hover {
  color: #fff;
  background-color: #2b99b9;
  border-color: #2991ae;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #2b99b9;
  border-color: #2991ae;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2991ae;
  border-color: #2688a4;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
}

.btn-warning {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:hover {
  color: #313a46;
  background-color: #d9a000;
  border-color: #cc9600;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #313a46;
  background-color: #d9a000;
  border-color: #cc9600;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc9600;
  border-color: #bf8d00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f9375e;
  border-color: #f82b54;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #f9375e;
  border-color: #f82b54;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f82b54;
  border-color: #f81e4a;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}

.btn-light {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}
.btn-light:hover {
  color: #313a46;
  background-color: #d4deeb;
  border-color: #cbd7e7;
}
.btn-light:focus,
.btn-light.focus {
  color: #313a46;
  background-color: #d4deeb;
  border-color: #cbd7e7;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #313a46;
  background-color: #cbd7e7;
  border-color: #c3d1e3;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:hover {
  color: #fff;
  background-color: #212730;
  border-color: #1c2128;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #212730;
  border-color: #1c2128;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2128;
  border-color: #171b21;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--primary);
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #0acf97;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}

.btn-outline-info {
  color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #39afd1;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.btn-outline-warning {
  color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:hover {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffbc00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.btn-outline-danger {
  color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fa5c7c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}

.btn-outline-light {
  color: #eef2f7;
  border-color: #eef2f7;
}
.btn-outline-light:hover {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #eef2f7;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #313a46;
  background-color: #eef2f7;
  border-color: #eef2f7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
}

.btn-outline-dark {
  color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #313a46;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.btn-link {
  font-weight: 400;
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover {
  color: #4DBFA6;
  text-decoration: none;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #adb5bd;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}

.btn-block {
  display: block;
  color: white;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4eaf2;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1367px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #272e37;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #313a46;
  text-decoration: none;
  background-color: #f8fafc;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #6c757d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.45rem 0.9rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8725rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.9rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.35rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.175rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--primary);
  border-color: var(--primary);
  background-color: var(--primary);
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.175rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #dee2e6 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.175rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.175rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #dee2e6;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.45rem 1.9rem 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #c1c9d0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.9rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #c1c9d0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.45rem 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.45rem 0.9rem;
  line-height: 1.5;
  color: #6c757d;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.2rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: white;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  background-color: var(--primary);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: white;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--primary);
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1366.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1367px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #eef2f7;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card .card-header {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid #eef2f7;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #ced4da;
  content: "";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #adb5bd;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  float: right !important;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #313a46;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #313a46;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
  box-shadow: 0 0 0 0.1rem rgba(114, 124, 245, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: var(--primary);
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #28b5b5;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #0acf97;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #089e74;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #39afd1;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #2991ae;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.badge-warning {
  color: #313a46;
  background-color: #ffbc00;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #313a46;
  background-color: #cc9600;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fa5c7c;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #f82b54;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}

.badge-light {
  color: #313a46;
  background-color: #eef2f7;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #313a46;
  background-color: #cbd7e7;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 242, 247, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #313a46;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1c2128;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.85rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #3b407f;
  background-color: #e3e5fd;
  border-color: #d8dafc;
}
.alert-primary hr {
  border-top-color: #c0c4fa;
}
.alert-primary .alert-link {
  color: #2b2e5c;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #056c4f;
  background-color: #cef5ea;
  border-color: #baf2e2;
}
.alert-success hr {
  border-top-color: #a5eed9;
}
.alert-success .alert-link {
  color: #033b2b;
}

.alert-info {
  color: #1e5b6d;
  background-color: #d7eff6;
  border-color: #c8e9f2;
}
.alert-info hr {
  border-top-color: #b3e1ed;
}
.alert-info .alert-link {
  color: #133a45;
}

.alert-warning {
  color: #856200;
  background-color: #fff2cc;
  border-color: #ffecb8;
}
.alert-warning hr {
  border-top-color: #ffe59f;
}
.alert-warning .alert-link {
  color: #523c00;
}

.alert-danger {
  color: #823040;
  background-color: #fedee5;
  border-color: #fed1da;
}
.alert-danger hr {
  border-top-color: #fdb8c6;
}
.alert-danger .alert-link {
  color: #5d222e;
}

.alert-light {
  color: #7c7e80;
  background-color: #fcfcfd;
  border-color: #fafbfd;
}
.alert-light hr {
  border-top-color: #e8ecf6;
}
.alert-light .alert-link {
  color: #636566;
}

.alert-dark {
  color: #191e24;
  background-color: #d6d8da;
  border-color: #c5c8cb;
}
.alert-dark hr {
  border-top-color: #b8bbbf;
}
.alert-dark .alert-link {
  color: #040506;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--primary);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #eef2f7;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #f9f9fd;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1367px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #3b407f;
  background-color: #d8dafc;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #3b407f;
  background-color: #c0c4fa;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #3b407f;
  border-color: #3b407f;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #056c4f;
  background-color: #baf2e2;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #056c4f;
  background-color: #a5eed9;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #056c4f;
  border-color: #056c4f;
}

.list-group-item-info {
  color: #1e5b6d;
  background-color: #c8e9f2;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #1e5b6d;
  background-color: #b3e1ed;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1e5b6d;
  border-color: #1e5b6d;
}

.list-group-item-warning {
  color: #856200;
  background-color: #ffecb8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856200;
  background-color: #ffe59f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856200;
  border-color: #856200;
}

.list-group-item-danger {
  color: #823040;
  background-color: #fed1da;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #823040;
  background-color: #fdb8c6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #823040;
  border-color: #823040;
}

.list-group-item-light {
  color: #7c7e80;
  background-color: #fafbfd;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7c7e80;
  background-color: #e8ecf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7c7e80;
  border-color: #7c7e80;
}

.list-group-item-dark {
  color: #191e24;
  background-color: #c5c8cb;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #191e24;
  background-color: #b8bbbf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #191e24;
  border-color: #191e24;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #313a46;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1367px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #343a40;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #343a40;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #343a40;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #343a40;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: #f1f3fa;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.2rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f1f3fa;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f1f3fa;
  border-bottom: 1px solid #dee3f3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #28b5b5 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #0acf97 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #089e74 !important;
}

.bg-info {
  background-color: #39afd1 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2991ae !important;
}

.bg-warning {
  background-color: #ffbc00 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9600 !important;
}

.bg-danger {
  background-color: #fa5c7c !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f82b54 !important;
}

.bg-light {
  background-color: #eef2f7 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd7e7 !important;
}

.bg-dark {
  background-color: #313a46 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1c2128 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #0acf97 !important;
}

.border-info {
  border-color: #39afd1 !important;
}

.border-warning {
  border-color: #ffbc00 !important;
}

.border-danger {
  border-color: #fa5c7c !important;
}

.border-light {
  border-color: #eef2f7 !important;
}

.border-dark {
  border-color: #313a46 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1367px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1367px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1367px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 4.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1367px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1367px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-lightgray {
  color: lightgray !important;
}

.text-primary {
  color: var(--primary) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #4DBFA6 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #0acf97 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #068662 !important;
}

.text-info {
  color: #39afd1 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #247f9a !important;
}

.text-warning {
  color: #ffbc00 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b38400 !important;
}

.text-danger {
  color: #fa5c7c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #f81240 !important;
}

.text-light {
  color: #eef2f7 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #bacadf !important;
}

.text-dark {
  color: #313a46 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121519 !important;
}

.text-body {
  color: #6c757d !important;
}

.text-muted {
  color: #98a6ad !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #eef2f7;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #eef2f7;
  }
}

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.logo {
  display: block;
  line-height: 70px;
  width: 260px;
  position: fixed;
  top: 0;
}
.logo span.logo-lg {
  display: block;
}
.logo span.logo-sm {
  display: none;
}
.logo.logo-light {
  display: block;
}
.logo.logo-dark {
  display: none;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 70px 12px 65px;
  min-height: 100vh;
}

.left-side-menu {
  width: 260px;
  z-index: 10;
  background: #323D49;
  bottom: 0;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  top: 0;
  padding-top: 70px;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.side-nav .side-nav-link {
  color: #cedce4;
  display: block;
  padding: 10px 30px;
  font-size: 0.9375rem;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.side-nav .side-nav-link:hover,
.side-nav .side-nav-link:focus,
.side-nav .side-nav-link:active {
  color: #ffffff;
  text-decoration: none;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
.side-nav .side-nav-link i {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  vertical-align: middle;
  width: 20px;
}

.side-nav .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  position: absolute;
  right: 30px;
  display: inline-block;
  font-family: "Material Design Icons";
  text-rendering: auto;
  line-height: 1.5rem;
  font-size: 1.1rem;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.side-nav .menu-arrow:before {
  content: "\F142";
}

.side-nav .badge {
  margin-top: 3px;
}

.side-nav .side-nav-item.mm-active > a .menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.side-nav .side-nav-title {
  padding: 12px 30px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #cedce4;
  font-weight: 700;
}

.side-nav .mm-active > a {
  color: #ffffff !important;
}

body[data-leftbar-compact-mode="condensed"] {
  min-height: 1600px;
}
body[data-leftbar-compact-mode="condensed"] .wrapper .left-side-menu {
  position: absolute;
  padding-top: 0;
  width: 70px;
  z-index: 5;
  padding-top: 70px;
}
body[data-leftbar-compact-mode="condensed"]
  .wrapper
  .left-side-menu
  .simplebar-mask,
body[data-leftbar-compact-mode="condensed"]
  .wrapper
  .left-side-menu
  .simplebar-content-wrapper {
  overflow: visible !important;
}
body[data-leftbar-compact-mode="condensed"]
  .wrapper
  .left-side-menu
  .simplebar-scrollbar {
  display: none !important;
}
body[data-leftbar-compact-mode="condensed"]
  .wrapper
  .left-side-menu
  .simplebar-offset {
  bottom: 0 !important;
}
body[data-leftbar-compact-mode="condensed"] .wrapper .left-side-menu .logo {
  width: 70px;
  z-index: 1;
  background: linear-gradient(135deg, #8f75da 0%, var(--primary) 60%);
}
body[data-leftbar-compact-mode="condensed"] .wrapper .help-box {
  display: none;
}
body[data-leftbar-compact-mode="condensed"] .wrapper .content-page {
  margin-left: 70px;
}
body[data-leftbar-compact-mode="condensed"] .wrapper .navbar-custom,
body[data-leftbar-compact-mode="condensed"] .wrapper .footer {
  left: 70px;
}
body[data-leftbar-compact-mode="condensed"] .side-nav .side-nav-title,
body[data-leftbar-compact-mode="condensed"] .side-nav .menu-arrow,
body[data-leftbar-compact-mode="condensed"] .side-nav .badge,
body[data-leftbar-compact-mode="condensed"] .side-nav .collapse.in,
body[data-leftbar-compact-mode="condensed"] .side-nav .mm-collapse {
  display: none !important;
}
body[data-leftbar-compact-mode="condensed"] .side-nav .nav.collapse {
  height: inherit !important;
}
body[data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item {
  position: relative;
  white-space: nowrap;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link {
  padding: 15px 20px;
  min-height: 56px;
  -webkit-transition: none;
  transition: none;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover,
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus {
  color: #ffffff;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link
  i {
  font-size: 1.125rem;
  margin-right: 20px;
  margin-left: 6px;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link
  span {
  display: none;
  padding-left: 10px;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  position: relative;
  width: 260px;
  color: #fff;
  background: linear-gradient(135deg, #8f75da 0%, var(--primary) 60%);
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link
  span {
  display: inline;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul {
  display: block !important;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  padding: 5px 0;
  z-index: 9999;
  background: linear-gradient(135deg, #8f75da 0%, var(--primary) 60%);
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a {
  padding: 8px 20px;
  position: relative;
  width: 190px;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: #ffffff;
}
body[data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  ul {
  left: 190px;
  top: 0;
}
body[data-leftbar-compact-mode="condensed"] .logo span.logo-lg {
  display: none;
}
body[data-leftbar-compact-mode="condensed"] .logo span.logo-sm {
  display: block;
  line-height: 70px;
  color: var(--primary);
}

@media (max-width: 767.98px) {
  body {
    overflow-x: hidden;
  }
  .left-side-menu {
    -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable .left-side-menu {
    display: block;
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 65px 10px 65px;
  }
  body[data-leftbar-compact-mode="condensed"] .left-side-menu {
    margin-left: -70px;
  }
  .logo span.logo-lg {
    display: block;
  }
  .logo span.logo-sm {
    display: none;
  }
}

.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.07);
}
.help-box .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

body[data-leftbar-theme="light"] .help-box {
  background-color: var(--primary);
}

body[data-leftbar-theme="light"] .logo.logo-light {
  display: none;
}

body[data-leftbar-theme="light"] .logo.logo-dark {
  display: block;
}

body[data-layout="topnav"] .content-page {
  margin-left: 0 !important;
  padding: 0 0 60px 0;
}

body[data-layout-mode="boxed"] {
  background-color: #ffffff;
}
body[data-layout-mode="boxed"] .wrapper {
  max-width: 1300px;
  margin: 0 auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .logo {
  position: relative;
  margin-top: -70px;
}

@media (min-width: 1367px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    padding-bottom: 0;
  }
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"])
    .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"])
    .left-side-menu {
    position: relative;
    min-width: 260px;
    max-width: 260px;
    padding-top: 0;
  }
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"])
    .logo {
    position: relative;
    margin-top: 0;
  }
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"])
    .content-page {
    margin-left: 0;
    width: 100%;
    padding-bottom: 60px;
  }
}

body[data-layout="detached"] {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  body[data-layout="detached"] .container-fluid,
  body[data-layout="detached"] .container-sm,
  body[data-layout="detached"] .container-md,
  body[data-layout="detached"] .container-lg,
  body[data-layout="detached"] .container-xl {
    max-width: 95%;
  }
}
body[data-layout="detached"][data-layout-mode="boxed"] .wrapper {
  max-width: 100%;
}
body[data-layout="detached"][data-leftbar-compact-mode="scrollable"] .wrapper {
  padding-top: 70px;
}
body[data-layout="detached"] .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: inherit;
}
body[data-layout="detached"] .content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 60px;
}
body[data-layout="detached"] .left-side-menu {
  position: relative;
  background: #ffffff !important;
  min-width: 260px;
  max-width: 260px;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-top: 30px;
  padding-top: 0 !important;
  z-index: 1001 !important;
}
body[data-layout="detached"] .left-side-menu .side-nav .side-nav-link {
  color: #6c757d !important;
}
body[data-layout="detached"] .left-side-menu .side-nav .side-nav-link:hover,
body[data-layout="detached"] .left-side-menu .side-nav .side-nav-link:focus,
body[data-layout="detached"] .left-side-menu .side-nav .side-nav-link:active {
  color: var(--primary) !important;
}
body[data-layout="detached"] .left-side-menu .side-nav .mm-active > a {
  color: var(--primary) !important;
}
body[data-layout="detached"] .left-side-menu .side-nav .side-nav-title {
  color: #6c757d;
}
body[data-layout="detached"] .leftbar-user {
  /* background: url("../images/waves.png") no-repeat; */
  padding: 30px 20px;
  text-align: center;
}
body[data-layout="detached"] .leftbar-user .leftbar-user-name {
  font-weight: 700;
  color: #313a46;
  margin-left: 12px;
  margin-top: 8px;
  display: block;
}
@media (max-width: 767.98px) {
  body[data-layout="detached"].sidebar-enable .left-side-menu {
    position: fixed;
    left: 0;
    overflow-y: auto;
    margin-top: 0;
  }
  body[data-layout="detached"] .wrapper {
    max-width: 100%;
  }
  body[data-layout="detached"] .content-page {
    margin-left: 0 !important;
    padding: 0 10px 60px 10px;
  }
  body[data-layout="detached"] .lang-switch {
    display: none;
  }
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .left-side-menu {
  max-width: 70px;
  min-width: 70px;
  position: relative;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .leftbar-user {
  display: none;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .content-page {
  margin-left: 0;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .footer {
  left: 0;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover,
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus {
  color: #ffffff;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--primary);
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: var(--primary);
}

.button-menu-mobile .lines {
  width: 18px;
  display: block;
  position: relative;
  height: 16px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 26px;
  margin-left: 10px;
}

.button-menu-mobile span {
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.button-menu-mobile span:nth-of-type(2) {
  width: 24px;
}

.button-menu-mobile.disable-btn {
  display: none;
}

body[data-leftbar-theme="light"] .left-side-menu {
  background: #ffffff;
}
body[data-leftbar-theme="light"] .left-side-menu .logo {
  background: #ffffff !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-link {
  color: #6c757d;
}
body[data-leftbar-theme="light"] .side-nav .side-nav-link:hover,
body[data-leftbar-theme="light"] .side-nav .side-nav-link:focus,
body[data-leftbar-theme="light"] .side-nav .side-nav-link:active {
  color: var(--primary);
}

body[data-leftbar-theme="light"] .side-nav .mm-active > a {
  color: var(--primary) !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-title {
  color: #6c757d;
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover,
body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus {
  color: #ffffff;
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--primary);
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: var(--primary);
}

body[data-leftbar-theme="dark"] .left-side-menu {
  background: #313a46;
}
body[data-leftbar-theme="dark"] .left-side-menu .logo {
  background: #313a46 !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: #8391a2;
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:hover,
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:active {
  color: #bccee4;
}

body[data-leftbar-theme="dark"] .side-nav .mm-active > a {
  color: #ffffff !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-title {
  color: #8391a2;
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus {
  color: #bccee4;
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--primary);
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul {
  background: #313a46;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: #bccee4;
}

.navbar-custom {
  padding: 0 24px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  min-height: 70px;
  position: fixed;
  left: 260px;
  top: 0;
  right: 0;
  z-index: 1001;
}
.navbar-custom .topbar-left {
  background-color: #ffffff;
  height: 70px;
  position: fixed;
  z-index: 1;
  width: 260px;
  text-align: center;
  top: 0;
  left: 0;
}
.navbar-custom .topbar-left .logo {
  line-height: 70px;
}
.navbar-custom .topbar-left .logo i {
  display: none;
}
.navbar-custom .topbar-right-menu {
  position: relative;
  z-index: 1;
}
.navbar-custom .topbar-right-menu li {
  float: left;
  max-height: 70px;
}
.navbar-custom .topbar-right-menu li.show .nav-link {
  color: #6c757d;
}
.navbar-custom .topbar-right-menu .nav-link {
  padding: 0;
  color: #98a6ad;
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 10px;
  position: relative;
}
.navbar-custom .app-search form {
  padding: calc(32px / 2) 5px calc(32px / 2) 0;
  overflow: hidden;
  max-width: 320px;
}

.topbar-dropdown .nav-link {
  line-height: 70px;
}

/* Search */
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f1f3fa;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.app-search span.search-icon {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
  top: 0;
}

.app-search .input-group-append {
  margin-left: 0;
  z-index: 4;
}

/* Notification */
.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon {
  font-size: 22px;
  vertical-align: middle;
  line-height: 70px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 6px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small {
  display: block;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.notification-list .notify-item .user-msg {
  margin-left: 45px;
  white-space: normal;
  line-height: 16px;
}
.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.profile-dropdown {
  min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  padding: calc(31px / 2) 20px calc(31px / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px;
}
.nav-user .account-user-avatar {
  position: absolute;
  top: calc(38px / 2);
  left: 15px;
}
.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}
.nav-user .account-position {
  display: block;
  font-size: 12px;
  margin-top: -3px;
}
.nav-user .account-user-name {
  display: block;
  font-weight: 600;
}

.button-menu-mobile {
  border: none;
  color: #313a46;
  height: 70px;
  line-height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
}
.button-menu-mobile i {
  vertical-align: middle;
}
.button-menu-mobile.disable-btn {
  display: none;
}

[data-keep-enlarged="true"] .navbar-custom {
  padding-left: 0;
}

[data-keep-enlarged="true"] .button-menu-mobile.disable-btn {
  display: inline-block;
}

@media (max-width: 600px) {
  .navbar-custom .topbar-right-menu {
    position: initial;
  }
  .navbar-custom .dropdown {
    position: static;
  }
  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 70px;
    right: 0;
  }
  .navbar-custom .app-search {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-custom {
    left: 0;
    padding: 0;
  }
  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;
  }
  .nav-user .account-position,
  .nav-user .account-user-name {
    display: none;
  }
}

@media (max-width: 360px) {
  .navbar-custom .topbar-right-menu .nav-link {
    margin: 0 6px;
  }
  .navbar-custom.topnav-navbar .container-fluid,
  .navbar-custom.topnav-navbar .container-sm,
  .navbar-custom.topnav-navbar .container-md,
  .navbar-custom.topnav-navbar .container-lg,
  .navbar-custom.topnav-navbar .container-xl {
    padding-right: 12px;
    padding-left: 12px;
  }
  .navbar-custom.topnav-navbar .navbar-toggle {
    margin: 27px 3px;
  }
  .navbar-custom.topnav-navbar .button-menu-mobile {
    width: auto;
  }
}

@media (min-width: 1367px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"])
    .navbar-custom {
    position: absolute;
  }
}

.topnav-navbar-dark {
  background-color: #313a46;
}
.topnav-navbar-dark .nav-user {
  background-color: #3c4655;
  border: 1px solid #414d5d;
}
.topnav-navbar-dark .topbar-right-menu li.show .nav-link {
  color: #fff;
}
.topnav-navbar-dark .app-search .form-control {
  background-color: #3c4655;
  color: #fff;
}
.topnav-navbar-dark .app-search span {
  color: #98a6ad;
}
.topnav-navbar-dark .navbar-toggle span {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

body[data-layout-mode="boxed"] .navbar-custom {
  position: relative;
  left: 0 !important;
  margin: -70px -12px 0;
}

body[data-layout-mode="boxed"][data-layout="topnav"] .navbar-custom {
  margin: 0;
}

.right-bar {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgba(49, 58, 70, 0.1),
    0 1px 0 0 rgba(49, 58, 70, 0.08);
  box-shadow: 0 0 24px 0 rgba(49, 58, 70, 0.1), 0 1px 0 0 rgba(49, 58, 70, 0.08);
  display: block;
  position: fixed;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  width: 280px;
  z-index: 9999;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
  padding-bottom: 80px;
}
.right-bar .rightbar-title {
  background-color: #313a46;
  padding: 27px 25px;
  color: #ffffff;
}
.right-bar .right-bar-toggle {
  background-color: #444e5a;
  height: 24px;
  width: 24px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  margin-top: -4px;
}

.rightbar-overlay {
  background-color: #37404a;
  opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}

.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}

.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}

.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px;
}

.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}

.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title {
  color: #6c757d;
}
.text-title:hover {
  color: #6c757d;
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }
  .page-title-box .breadcrumb {
    display: none;
  }
  .page-title-box .page-title-right {
    display: none;
  }
}

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}

.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  padding: 19px 24px 20px;
  position: absolute;
  right: 0;
  color: #98a6ad;
  left: 260px;
}
.footer .footer-links a {
  color: #98a6ad;
  margin-left: 1.5rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: #313a46;
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}

.footer-alt {
  left: 0;
  border: none;
  text-align: center;
}

body[data-layout="topnav"] .footer {
  left: 0 !important;
  padding: 19px 0 20px;
}

body[data-layout="topnav"][data-layout-mode="boxed"] .footer {
  max-width: 1300px;
}

body[data-layout-mode="boxed"] .footer {
  border: none;
  margin: 0 auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  max-width: calc(1300px - 260px);
}

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .footer {
  max-width: calc(1300px - 70px);
}

body[data-layout="detached"] .footer {
  left: 0;
}
body[data-layout="detached"] .footer .container-fluid,
body[data-layout="detached"] .footer .container-sm,
body[data-layout="detached"] .footer .container-md,
body[data-layout="detached"] .footer .container-lg,
body[data-layout="detached"] .footer .container-xl {
  max-width: 100%;
  padding: 0;
}

.topnav-navbar {
  padding: 0;
  margin: 0;
  min-height: 70px;
  position: relative;
  left: 0 !important;
  z-index: 1002;
}
.topnav-navbar .topnav-logo {
  line-height: 70px;
  float: left;
  padding-right: 20px;
  min-width: 160px;
}
.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}
.topnav-navbar .navbar-toggle {
  position: relative;
  cursor: pointer;
  float: left;
  margin: 27px 20px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.topnav-navbar .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  height: 16px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.topnav-navbar .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: rgba(49, 58, 70, 0.8);
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.topnav-navbar .navbar-toggle.open span {
  position: absolute;
}
.topnav-navbar .navbar-toggle.open span:first-child {
  top: 7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.topnav-navbar .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
.topnav-navbar .navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.topnav-navbar .app-search {
  float: left;
}

.topnav {
  background: #313a46;
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 0.9375rem;
  position: relative;
  padding: 1rem 1.3rem;
}

.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

body[data-layout="topnav"] .container-fluid,
body[data-layout="topnav"] .container-sm,
body[data-layout="topnav"] .container-md,
body[data-layout="topnav"] .container-lg,
body[data-layout="topnav"] .container-xl {
  padding-right: 24px;
  padding-left: 24px;
}

@media (min-width: 992px) {
  body[data-layout="topnav"] .container-fluid,
  body[data-layout="topnav"] .container-sm,
  body[data-layout="topnav"] .container-md,
  body[data-layout="topnav"] .container-lg,
  body[data-layout="topnav"] .container-xl {
    max-width: 95%;
  }
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-fluid,
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-sm,
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-md,
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-lg,
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-xl {
    max-width: 97%;
  }
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    min-width: calc(10rem + 1.5rem);
    font-size: calc(0.9rem - 0.01rem);
  }
  .topnav .dropdown .dropdown-menu .arrow-down::after {
    right: 15px;
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-toggle {
    display: none;
  }
  .dropdown.active > a.dropdown-item {
    color: #313a46;
    background-color: #f8fafc;
  }
}

@media (min-width: 1367px) {
  body[data-layout="topnav"] .container-fluid,
  body[data-layout="topnav"] .container-sm,
  body[data-layout="topnav"] .container-md,
  body[data-layout="topnav"] .container-lg,
  body[data-layout="topnav"] .container-xl {
    max-width: 85%;
  }
}

@media (max-width: 991.98px) {
  .topnav-navbar .topnav-logo-lg {
    display: none;
  }
  .topnav-navbar .topnav-logo {
    min-width: 50px;
    padding-right: 0;
    text-align: center;
  }
  .topnav-navbar .topnav-logo-sm {
    display: block !important;
  }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.3rem;
  }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 15px;
  }
  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }
  .topnav .navbar-dark .dropdown .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
  }
  .topnav .navbar-dark .dropdown .dropdown-item.active,
  .topnav .navbar-dark .dropdown .dropdown-item:active {
    color: #fff;
  }
  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}

.custom-accordion .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-accordion .card-header {
  background-color: #f1f3fa;
}

.custom-accordion .card-body {
  border: 1px solid #f1f3fa;
}

.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}

.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\F142";
}

.custom-accordion-title {
  color: #313a46;
  position: relative;
}
.custom-accordion-title:hover {
  color: #414d5d;
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--primary);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
}

.btn .mdi:before {
  line-height: initial;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-primary {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5);
}

.btn-secondary {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(108, 117, 125, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(108, 117, 125, 0.5);
}

.btn-success {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(10, 207, 151, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(10, 207, 151, 0.5);
}

.btn-info {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(57, 175, 209, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(57, 175, 209, 0.5);
}

.btn-warning {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
}

.btn-danger {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(250, 92, 124, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(250, 92, 124, 0.5);
}

.btn-light {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(238, 242, 247, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(238, 242, 247, 0.5);
}

.btn-dark {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(49, 58, 70, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(49, 58, 70, 0.5);
}

.badge-primary-lighten {
  color: var(--primary);
  background-color: rgba(114, 124, 245, 0.18);
}
.badge-primary-lighten[href]:hover,
.badge-primary-lighten[href]:focus {
  color: var(--primary);
  text-decoration: none;
  background-color: rgba(114, 124, 245, 0.4);
}

.badge-secondary-lighten {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.18);
}
.badge-secondary-lighten[href]:hover,
.badge-secondary-lighten[href]:focus {
  color: #6c757d;
  text-decoration: none;
  background-color: rgba(108, 117, 125, 0.4);
}

.badge-success-lighten {
  color: #0acf97;
  background-color: rgba(10, 207, 151, 0.18);
}
.badge-success-lighten[href]:hover,
.badge-success-lighten[href]:focus {
  color: #0acf97;
  text-decoration: none;
  background-color: rgba(10, 207, 151, 0.4);
}

.badge-info-lighten {
  color: #39afd1;
  background-color: rgba(57, 175, 209, 0.18);
}
.badge-info-lighten[href]:hover,
.badge-info-lighten[href]:focus {
  color: #39afd1;
  text-decoration: none;
  background-color: rgba(57, 175, 209, 0.4);
}

.badge-warning-lighten {
  color: #ffbc00;
  background-color: rgba(255, 188, 0, 0.18);
}
.badge-warning-lighten[href]:hover,
.badge-warning-lighten[href]:focus {
  color: #ffbc00;
  text-decoration: none;
  background-color: rgba(255, 188, 0, 0.4);
}

.badge-danger-lighten {
  color: #fa5c7c;
  background-color: rgba(250, 92, 124, 0.18);
}
.badge-danger-lighten[href]:hover,
.badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  text-decoration: none;
  background-color: rgba(250, 92, 124, 0.4);
}

.badge-light-lighten {
  color: #eef2f7;
  background-color: rgba(238, 242, 247, 0.18);
}
.badge-light-lighten[href]:hover,
.badge-light-lighten[href]:focus {
  color: #eef2f7;
  text-decoration: none;
  background-color: rgba(238, 242, 247, 0.4);
}

.badge-dark-lighten {
  color: #313a46;
  background-color: rgba(49, 58, 70, 0.18);
}
.badge-dark-lighten[href]:hover,
.badge-dark-lighten[href]:focus {
  color: #313a46;
  text-decoration: none;
  background-color: rgba(49, 58, 70, 0.4);
}

.badge-outline-primary {
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: transparent;
}
.badge-outline-primary[href]:hover,
.badge-outline-primary[href]:focus {
  color: var(--primary);
  text-decoration: none;
  background-color: rgba(114, 124, 245, 0.2);
}

.badge-outline-secondary {
  color: #6c757d;
  border: 1px solid #6c757d;
  background-color: transparent;
}
.badge-outline-secondary[href]:hover,
.badge-outline-secondary[href]:focus {
  color: #6c757d;
  text-decoration: none;
  background-color: rgba(108, 117, 125, 0.2);
}

.badge-outline-success {
  color: #0acf97;
  border: 1px solid #0acf97;
  background-color: transparent;
}
.badge-outline-success[href]:hover,
.badge-outline-success[href]:focus {
  color: #0acf97;
  text-decoration: none;
  background-color: rgba(10, 207, 151, 0.2);
}

.badge-outline-info {
  color: #39afd1;
  border: 1px solid #39afd1;
  background-color: transparent;
}
.badge-outline-info[href]:hover,
.badge-outline-info[href]:focus {
  color: #39afd1;
  text-decoration: none;
  background-color: rgba(57, 175, 209, 0.2);
}

.badge-outline-warning {
  color: #ffbc00;
  border: 1px solid #ffbc00;
  background-color: transparent;
}
.badge-outline-warning[href]:hover,
.badge-outline-warning[href]:focus {
  color: #ffbc00;
  text-decoration: none;
  background-color: rgba(255, 188, 0, 0.2);
}

.badge-outline-danger {
  color: #fa5c7c;
  border: 1px solid #fa5c7c;
  background-color: transparent;
}
.badge-outline-danger[href]:hover,
.badge-outline-danger[href]:focus {
  color: #fa5c7c;
  text-decoration: none;
  background-color: rgba(250, 92, 124, 0.2);
}

.badge-outline-light {
  color: #eef2f7;
  border: 1px solid #eef2f7;
  background-color: transparent;
}
.badge-outline-light[href]:hover,
.badge-outline-light[href]:focus {
  color: #eef2f7;
  text-decoration: none;
  background-color: rgba(238, 242, 247, 0.2);
}

.badge-outline-dark {
  color: #313a46;
  border: 1px solid #313a46;
  background-color: transparent;
}
.badge-outline-dark[href]:hover,
.badge-outline-dark[href]:focus {
  color: #313a46;
  text-decoration: none;
  background-color: rgba(49, 58, 70, 0.2);
}

.card {
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  margin-bottom: 24px;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\F415";
}

.card-title,
.card-header {
  margin-top: 0;
}

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: #313a46;
  -webkit-animation: rotatebox 1.2s infinite ease-in-out;
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

@-webkit-keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.card-pricing {
  position: relative;
}
.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
  font-size: 22px;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 56px;
  border-radius: 50%;
}
.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
  font-size: 40%;
  color: #98a6ad;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card-pricing .card-pricing-features {
  color: #98a6ad;
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (min-width: 768px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}

.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(250, 92, 124, 0.2);
  color: #fa5c7c;
  padding: 5px 0;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.dropdown-menu-animated {
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated i {
  display: inline-block;
}
.dropdown-menu-animated.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.dropdown-menu-animated.dropdown-menu[x-placement^="right"],
.dropdown-menu-animated.dropdown-menu[x-placement^="top"],
.dropdown-menu-animated.dropdown-menu[x-placement^="left"] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}

@-webkit-keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@media (min-width: 600px) {
  .dropdown-lg {
    width: 320px;
  }
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #6c757d;
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  background-color: #f8f9fa;
  color: #272e37;
}

.arrow-none:after {
  display: none;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 2em;
  color: #313a46;
  max-height: 420px;
  margin: -10px 0 -30px;
  border: 1px solid rgba(152, 166, 173, 0.2);
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #313a46;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #02a8b5;
}

.hljs-string,
.hljs-doctag {
  color: #fa5c7c;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #fa5c7c;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #0768d1;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #02a8b5;
}

.hljs-symbol,
.hljs-bullet {
  color: #ff679b;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #39afd1;
}

.hljs-meta {
  color: #343a40;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-comment {
  color: #ced4da;
}

.form-control-light {
  background-color: #f1f3fa !important;
  border-color: #f1f3fa !important;
}

input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8725rem + 2px);
}

.password-eye:before {
  font-family: "Material Design Icons";
  content: "\F6CF";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px;
}

.show-password .password-eye:before {
  content: "\F6D0";
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #fff;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-transform: translate(25%, 0) !important;
  transform: translate(25%, 0) !important;
}
.modal-right button.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right {
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  -ms-flex-line-pack: center;
  align-content: center;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}
.modal-colored-header .close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-header,
.modal-filled .modal-footer {
  border: none;
}
.modal-filled .close {
  color: #fff !important;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: #6c757d;
  font-weight: 600;
}

.nav-pills > a {
  color: #6c757d;
  font-weight: 600;
}

.bg-nav-pills {
  background-color: #eef2f7;
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -2px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid var(--primary);
}

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px;
  border: none;
}

.popover-header {
  margin-top: 0;
}

@media print {
  .left-side-menu,
  .right-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none;
  }
  .card-body,
  .content-page,
  .right-bar,
  .content,
  body {
    padding: 0;
    margin: 0;
  }
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

body.loading {
  visibility: hidden;
}

button,
a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: 700;
}

.ribbon-box {
  position: relative;
  /* Ribbon two */
}
.ribbon-box .ribbon {
  position: relative;
  clear: both;
  padding: 5px 12px;
  margin-bottom: 15px;
  -webkit-box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.ribbon-box .ribbon:before {
  content: " ";
  border-style: solid;
  border-width: 10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-bottom: -10px;
  z-index: -1;
}
.ribbon-box .ribbon.float-left {
  margin-left: -30px;
  border-radius: 0 3px 3px 0;
}
.ribbon-box .ribbon.float-right {
  margin-right: -30px;
  border-radius: 3px 0 0 3px;
}
.ribbon-box .ribbon.float-right:before {
  right: 0;
}
.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px auto;
}
.ribbon-box .ribbon-content {
  clear: both;
}
.ribbon-box .ribbon-primary {
  background: var(--primary);
}
.ribbon-box .ribbon-primary:before {
  border-color: #5a66f3 transparent transparent;
}
.ribbon-box .ribbon-secondary {
  background: #6c757d;
}
.ribbon-box .ribbon-secondary:before {
  border-color: #60686f transparent transparent;
}
.ribbon-box .ribbon-success {
  background: #0acf97;
}
.ribbon-box .ribbon-success:before {
  border-color: #09b785 transparent transparent;
}
.ribbon-box .ribbon-info {
  background: #39afd1;
}
.ribbon-box .ribbon-info:before {
  border-color: #2da2c3 transparent transparent;
}
.ribbon-box .ribbon-warning {
  background: #ffbc00;
}
.ribbon-box .ribbon-warning:before {
  border-color: #e6a900 transparent transparent;
}
.ribbon-box .ribbon-danger {
  background: #fa5c7c;
}
.ribbon-box .ribbon-danger:before {
  border-color: #f94368 transparent transparent;
}
.ribbon-box .ribbon-light {
  background: #eef2f7;
}
.ribbon-box .ribbon-light:before {
  border-color: #dde5ef transparent transparent;
}
.ribbon-box .ribbon-dark {
  background: #313a46;
}
.ribbon-box .ribbon-dark:before {
  border-color: #272e37 transparent transparent;
}
.ribbon-box .ribbon-two {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon-box .ribbon-two span {
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  -webkit-box-shadow: 0 0 8px 0 rgba(49, 58, 70, 0.08),
    0 1px 0 0 rgba(49, 58, 70, 0.03);
  box-shadow: 0 0 8px 0 rgba(49, 58, 70, 0.08), 0 1px 0 0 rgba(49, 58, 70, 0.03);
  position: absolute;
  top: 19px;
  left: -21px;
  font-weight: 600;
}
.ribbon-box .ribbon-two span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two-primary span {
  background: var(--primary);
}
.ribbon-box .ribbon-two-primary span:before {
  border-left: 3px solid #5a66f3;
  border-top: 3px solid #5a66f3;
}
.ribbon-box .ribbon-two-primary span:after {
  border-right: 3px solid #5a66f3;
  border-top: 3px solid #5a66f3;
}
.ribbon-box .ribbon-two-secondary span {
  background: #6c757d;
}
.ribbon-box .ribbon-two-secondary span:before {
  border-left: 3px solid #60686f;
  border-top: 3px solid #60686f;
}
.ribbon-box .ribbon-two-secondary span:after {
  border-right: 3px solid #60686f;
  border-top: 3px solid #60686f;
}
.ribbon-box .ribbon-two-success span {
  background: #0acf97;
}
.ribbon-box .ribbon-two-success span:before {
  border-left: 3px solid #09b785;
  border-top: 3px solid #09b785;
}
.ribbon-box .ribbon-two-success span:after {
  border-right: 3px solid #09b785;
  border-top: 3px solid #09b785;
}
.ribbon-box .ribbon-two-info span {
  background: #39afd1;
}
.ribbon-box .ribbon-two-info span:before {
  border-left: 3px solid #2da2c3;
  border-top: 3px solid #2da2c3;
}
.ribbon-box .ribbon-two-info span:after {
  border-right: 3px solid #2da2c3;
  border-top: 3px solid #2da2c3;
}
.ribbon-box .ribbon-two-warning span {
  background: #ffbc00;
}
.ribbon-box .ribbon-two-warning span:before {
  border-left: 3px solid #e6a900;
  border-top: 3px solid #e6a900;
}
.ribbon-box .ribbon-two-warning span:after {
  border-right: 3px solid #e6a900;
  border-top: 3px solid #e6a900;
}
.ribbon-box .ribbon-two-danger span {
  background: #fa5c7c;
}
.ribbon-box .ribbon-two-danger span:before {
  border-left: 3px solid #f94368;
  border-top: 3px solid #f94368;
}
.ribbon-box .ribbon-two-danger span:after {
  border-right: 3px solid #f94368;
  border-top: 3px solid #f94368;
}
.ribbon-box .ribbon-two-light span {
  background: #eef2f7;
}
.ribbon-box .ribbon-two-light span:before {
  border-left: 3px solid #dde5ef;
  border-top: 3px solid #dde5ef;
}
.ribbon-box .ribbon-two-light span:after {
  border-right: 3px solid #dde5ef;
  border-top: 3px solid #dde5ef;
}
.ribbon-box .ribbon-two-dark span {
  background: #313a46;
}
.ribbon-box .ribbon-two-dark span:before {
  border-left: 3px solid #272e37;
  border-top: 3px solid #272e37;
}
.ribbon-box .ribbon-two-dark span:after {
  border-right: 3px solid #272e37;
  border-top: 3px solid #272e37;
}

input[data-switch] {
  display: none;
}
input[data-switch] + label {
  width: 56px;
  height: 24px;
  background-color: #f1f3fa;
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
  color: #313a46;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  position: absolute;
  right: 3px;
  margin: 0 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
  content: "";
  position: absolute;
  left: 4px;
  background-color: #adb5bd;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2rem;
  height: 18px;
  width: 18px;
  top: 3px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
  background-color: var(--primary);
}
input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 4px;
}
input[data-switch]:checked + label:after {
  left: 34px;
  background-color: #f1f3fa;
}

input[data-switch="bool"] + label {
  background-color: #fa5c7c;
}

input:disabled + label {
  opacity: 0.5;
  cursor: default;
}

input[data-switch="bool"] + label:before,
input[data-switch="bool"]:checked + label:before {
  color: #fff !important;
}

input[data-switch="bool"] + label:after {
  background-color: #f1f3fa;
}

input[data-switch="primary"]:checked + label {
  background-color: var(--primary);
}

input[data-switch="secondary"]:checked + label {
  background-color: #6c757d;
}

input[data-switch="success"]:checked + label {
  background-color: #0acf97;
}

input[data-switch="info"]:checked + label {
  background-color: #39afd1;
}

input[data-switch="warning"]:checked + label {
  background-color: #ffbc00;
}

input[data-switch="danger"]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch="light"]:checked + label {
  background-color: #eef2f7;
}

input[data-switch="dark"]:checked + label {
  background-color: #313a46;
}

.table-centered th,
.table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  height: 30px;
  width: 30px;
}

.table .action-icon {
  color: #98a6ad;
  display: inline-block;
  font-size: 1.2rem;
  padding: 0 3px;
}
.table .action-icon:hover {
  color: #6c757d;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-weight: 700;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.bg-primary-lighten {
  background-color: rgba(114, 124, 245, 0.25) !important;
}

.bg-secondary-lighten {
  background-color: rgba(108, 117, 125, 0.25) !important;
}

.bg-success-lighten {
  background-color: rgba(10, 207, 151, 0.25) !important;
}

.bg-info-lighten {
  background-color: rgba(57, 175, 209, 0.25) !important;
}

.bg-warning-lighten {
  background-color: rgba(255, 188, 0, 0.25) !important;
}

.bg-danger-lighten {
  background-color: rgba(250, 92, 124, 0.25) !important;
}

.bg-light-lighten {
  background-color: rgba(238, 242, 247, 0.25) !important;
}

.bg-dark-lighten {
  background-color: rgba(49, 58, 70, 0.25) !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: var(--primary);
  font-size: 20px;
  background-color: rgba(114, 124, 245, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.dash-item-overlay {
  position: absolute;
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

.chart-content-bg {
  background-color: #f9f9fd;
}

.chart-content-border {
  border: 1px solid #eef2f7;
}

.chart-widget-list p {
  border-bottom: 1px solid #f1f3fa;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}
.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item:before {
  background-color: #f1f3fa;
  bottom: 0;
  content: "";
  left: 9px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid white;
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
}
.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: #343a40;
  display: block;
  margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: #adb5bd;
  display: block;
  font-size: 0.8125rem;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: 0.6875rem;
  position: absolute;
  right: 5px;
  top: 10px;
}

.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3;
}

.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.cta-box {
  /* background-image: url(../images/bg-pattern.png); */
  background-size: cover;
}
.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  list-style: none;
  padding: 0 15px;
}
.conversation-list li {
  margin-bottom: 20px;
}
.conversation-list li .conversation-actions {
  float: right;
  display: none;
}
.conversation-list li:hover .conversation-actions {
  display: block;
}
.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  background: #f1f3fa;
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  left: -11px;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #f1f3fa;
  border-width: 6px;
  margin-right: -1px;
  border-right-color: #f1f3fa;
}
.conversation-list .conversation-text {
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: #fef5e4;
}
.conversation-list .odd .ctext-wrap:after {
  border-color: transparent;
  border-left-color: #fef5e4;
  border-top-color: #fef5e4;
  right: -10px;
  left: auto;
}
.conversation-list .odd .conversation-actions {
  float: left;
}

.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%;
}
.calendar-widget .datepicker-inline tr td,
.calendar-widget .datepicker-inline tr th,
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td,
.calendar-widget table tr th,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  color: #fa5c7c !important;
  text-shadow: none;
  font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline td,
.calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
  height: 43px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .prev,
.calendar-widget .datepicker-inline .next,
.calendar-widget table .datepicker-switch,
.calendar-widget table .prev,
.calendar-widget table .next {
  font-size: 1.1rem;
  background-color: rgba(114, 124, 245, 0.1) !important;
  border-radius: 0;
  color: var(--primary);
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}
.social-list-item:hover {
  color: #98a6ad;
  border-color: #98a6ad;
}

.horizontal-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: #eef2f7;
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: var(--primary);
}
.horizontal-steps .horizontal-steps-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: var(--primary);
  background-color: currentColor;
  border: 0.25em solid #fff;
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: #0acf97;
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  color: #adb5bd;
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  -webkit-animation-name: animation-steps-current;
  animation-name: animation-steps-current;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: var(--primary);
}

@-webkit-keyframes animation-steps-current {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes animation-steps-current {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f3fa;
  z-index: 9999;
}

#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}

@-webkit-keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: var(--primary);
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background: #fa5c7c;
}
.bouncing-loader > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background: #0acf97;
}

.hero-section {
  position: relative;
  padding: 80px 0 120px 0;
}
.hero-section:after {
  content: " ";
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#8669ed),
    to(var(--primary))
  );
  background-image: linear-gradient(to bottom, #8669ed, var(--primary));
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  -webkit-transform: skewY(-3deg);
  transform: skewY(-3deg);
}
.hero-section .hero-title {
  line-height: 42px;
}

body.authentication-bg {
  /* background-image: url("../images/bg-pattern-light.svg"); */
  background-size: cover;
  background-position: center;
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode="condensed"] {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  /* background: url("../images/backgroundlogin.jpg"); */
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position-x: -24rem;
  background-position-y: -1rem;
}
.auth-fluid .auth-fluid-form-box {
  max-width: 50%;
  border-radius: 0;
  z-index: 2;
  padding: 3rem 5rem;
  background-color: #ffffff;
  position: relative;
  width: 100%;
}
.auth-fluid .auth-fluid-right {
  padding: 6rem 3rem;
  position: relative;
}

.auth-brand {
  margin-bottom: 4rem;
}
.auth-brand .logo-dark {
  display: block;
}
.auth-brand .logo-light {
  display: none;
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-user-testimonial p.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
}

@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem;
  }
}

@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}

.button-list {
  margin-left: -8px;
  margin-bottom: -12px;
}
.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.grid-structure .grid-container {
  background-color: #f1f3fa;
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px;
}

.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}

.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.icons-list-demo .col-md-4 {
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px;
}
.icons-list-demo .col-md-4:hover,
.icons-list-demo .col-md-4:hover i {
  color: var(--primary);
}

.text-error {
  color: var(--primary);
  text-shadow: rgba(var(--primary), 0.3) 5px 1px,
    rgba(var(--primary), 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem;
}

.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: var(--primary);
  background-color: rgba(114, 124, 245, 0.25);
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px;
}

.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 600;
  font-size: 16px;
  color: #313a46;
}

.faq-answer {
  margin-left: 50px;
}

.maintenance-icon {
  font-size: 22px;
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid #eef2f7;
  vertical-align: top;
  margin-bottom: 24px;
  border-radius: 0.25rem;
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}
.tasks .task-header {
  background-color: #f1f3fa;
  padding: 1rem;
  margin: 0 -1rem;
}

.task-list-items {
  min-height: 100px;
  position: relative;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.task-modal-content .form-control-light {
  background-color: #f7f9fb !important;
  border-color: #f7f9fb !important;
}

.gantt-task-details {
  min-width: 220px;
}

.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative;
}
.page-aside-left:before {
  content: "";
  background-color: #fff;
  width: 5px;
  position: absolute;
  right: -15px;
  height: 100%;
  bottom: -1.5rem;
}

.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid #fff;
  padding: 1.5rem 0 1.5rem 25px;
}

.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden;
}
.email-list > li {
  position: relative;
  display: block;
  height: 51px;
  line-height: 50px;
  cursor: default;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.email-list > li a {
  color: #6c757d;
}
.email-list > li a:hover {
  color: #343a40;
}
.email-list > li .col-mail {
  float: left;
  position: relative;
}
.email-list > li .email-sender-info {
  width: 320px;
}
.email-list > li .email-sender-info .star-toggle,
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  display: block;
  float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
  color: #adb5bd;
  margin-left: 10px;
  font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
}
.email-list > li .email-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.email-list > li .email-content .email-subject,
.email-list > li .email-content .email-date {
  position: absolute;
  top: 0;
}
.email-list > li .email-content .email-subject {
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list > li .email-content .email-date {
  right: 0;
  width: 100px;
  text-align: right;
  padding-left: 10px;
}
.email-list > li.active,
.email-list > li.mail-selected {
  background: #f1f3fa;
  -webkit-transition-duration: 0.05s;
  transition-duration: 0.05s;
}
.email-list > li.unread a {
  font-weight: 600;
  color: #272e37;
}
.email-list > li .email-action-icons {
  opacity: 0;
}
.email-list > li .email-action-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -180px;
}
.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: #fa5c7c;
}
.email-list > li:hover {
  background: #f1f3fa;
  -webkit-transition-duration: 0.05s;
  transition-duration: 0.05s;
}
.email-list > li:hover .email-action-icons {
  opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: 10px;
}
.email-list > li:hover .email-content .email-date {
  opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
  right: 180px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.email-menu-list a {
  color: #6c757d;
  padding: 12px 5px;
  display: block;
  font-size: 15px;
}
.email-menu-list a:hover {
  color: #343a40;
}
.email-menu-list a .badge {
  margin-top: 3px;
}

.labels-list a {
  padding: 7px 5px;
}

.write-mdg-box .CodeMirror {
  height: 150px;
}

@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
    padding: 0 10px 20px 10px;
  }
  .page-aside-left:before {
    width: 0;
  }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}

@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }
  .email-list li .email-sender-info {
    width: 150px;
  }
  .email-list li .email-sender-info .email-title {
    left: 80px;
  }
  .email-list li .email-content {
    display: none;
  }
}

.timeline {
  margin-bottom: 50px;
  position: relative;
}
.timeline:before {
  background-color: #dee2e6;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}

.timeline-show {
  position: relative;
}
.timeline-show .time-show-name {
  display: inline-block;
  border-radius: 4px;
  background-color: #eef2f7;
  padding: 7px 15px;
}

.timeline-box {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  display: block;
  margin: 15px 0;
  position: relative;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.timeline-album {
  margin-top: 12px;
}
.timeline-album a {
  display: inline-block;
  margin-right: 5px;
}
.timeline-album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .timeline .timeline-box {
    margin-left: 45px;
  }
  .timeline .timeline-icon {
    background: #dee2e6;
    border-radius: 50%;
    display: block;
    height: 24px;
    left: -56px;
    margin-top: -12px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 24px;
  }
  .timeline .timeline-icon i {
    color: #98a6ad;
    font-size: 1rem;
    vertical-align: middle;
  }
  .timeline .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
  }
  .timeline-lg-item {
    display: table-row;
  }
  .timeline-lg-item:before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0;
  }
  .timeline-lg-item.timeline-item-left:after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
    border-bottom: 12px solid transparent;
    border-left: 12px solid #fff !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -12px;
    position: absolute;
    right: -12px;
    top: 50%;
    width: 0;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }
  .timeline-lg-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -56px;
  }
  .timeline-lg-item.timeline-item-left:before {
    display: none;
  }
  .timeline-lg-item.timeline-item-left .timeline-box {
    margin-right: 45px;
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .timeline .timeline-icon {
    display: none;
  }
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdd49a),
    to(#fdf59a)
  );
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover:active {
  background-color: #fbf069\9;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
  background: #eee;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f3c17a),
    to(#f3e97a)
  );
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border-radius: 0;
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#b3b3b3),
    to(grey)
  );
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected.disabled:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected[disabled] {
  background-color: grey;
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover:active {
  background-color: #6669;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#08c),
    to(#04c)
  );
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active {
  background-color: #039\9;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#08c),
    to(#04c)
  );
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
  background-color: #039\9;
}

.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .next.disabled,
.datepicker .prev.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

.jq-toast-wrap,
.jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial, sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff;
}

.jq-toast-single h2 {
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
}

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0;
}

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1;
}

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc;
}

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1;
}

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6;
}

.select2-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #fff),
    to(#eee)
  );
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #eee),
    to(#ccc)
  );
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #eee)
  );
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #eee),
    to(#fff)
  );
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.jq-toast-wrap,
.jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial, sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff;
}

.jq-toast-single h2 {
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
}

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0;
}

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1;
}

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc;
}

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1;
}

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6;
}

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
  position: relative;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}

.bootstrap-timepicker.pull-right
  .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}

.bootstrap-timepicker.pull-right
  .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}

.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}

.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
  border-radius: 4px;
}

.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}

.bootstrap-timepicker-widget table td span {
  width: 100%;
}

.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333;
}

.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  border-radius: 4px;
  border-color: #ddd;
}

.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}

.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2em;
  text-align: center;
  line-height: 1;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px 0;
  bottom: 0;
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: "Poppins", sans-serif !important;
  fill: #adb5bd;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Poppins", sans-serif !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f9f9fd;
}

.apexcharts-legend-text {
  color: #98a6ad !important;
  font-family: "Poppins", sans-serif !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Poppins", sans-serif !important;
  fill: #adb5bd;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #fff;
}

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: #dee2e6;
}

.apexcharts-radar-series line {
  stroke: #dee2e6;
}

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  fill: #fff !important;
}

.apexcharts-datalabels-group text {
  fill: #adb5bd !important;
}

.scatter-images-chart .apexcharts-legend {
  overflow: hidden !important;
  min-height: 17px;
}

.scatter-images-chart .apexcharts-legend-marker {
  background: none !important;
  margin-right: 7px !important;
}

.scatter-images-chart .apexcharts-legend-series {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.apexcharts-pie-series path {
  stroke: transparent !important;
}

.apexcharts-track path {
  stroke: #edeff1;
}

.britechart,
.tick text {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
  stroke: #dee2e6;
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
  fill: #98a6ad;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 15px 0 5px 0;
}
.fc-toolbar h2 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 700;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #f8fafc;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f1f3fa;
}

.fc-button {
  background: #f8fafc;
  border: none;
  color: #adb5bd;
  text-shadow: none;
  text-transform: capitalize;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #eef2f7;
}

.fc-state-highlight {
  background: #dee2e6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #dee2e6;
}

.fc-unthemed td.fc-today {
  background: white;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #f1f3fa;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}

.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #f1f3fa;
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
}

@media (max-width: 767.98px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-center {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

.dataTables_wrapper.container-fluid,
.dataTables_wrapper.container-sm,
.dataTables_wrapper.container-md,
.dataTables_wrapper.container-lg,
.dataTables_wrapper.container-xl {
  padding: 0;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0.5rem;
  content: "\F360";
  font-family: "Material Design Icons";
  font-size: 1rem;
  top: 12px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\F35D";
  font-family: "Material Design Icons";
  top: 18px;
  font-size: 1rem;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: var(--primary);
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
  border-color: var(--primary);
}
table.dataTable tbody td:focus {
  outline: none !important;
}
table.dataTable tbody th.focus,
table.dataTable tbody td.focus {
  outline: 2px solid var(--primary) !important;
  outline-offset: -1px;
  background-color: rgba(114, 124, 245, 0.15);
}

.dataTables_info {
  font-weight: 600;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  background-color: #0acf97;
  top: auto;
  bottom: auto;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  background-color: #fa5c7c;
}

div.dt-button-info {
  background-color: var(--primary);
  border: none;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2 {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

@media (max-width: 767.98px) {
  li.paginate_button.previous,
  li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }
  li.paginate_button {
    display: none;
  }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem;
  }
}

.activate-select .sorting_1 {
  background-color: #f1f3fa;
}

.daterangepicker {
  font-family: "Poppins", sans-serif;
  border: 1px solid #e4eaf2;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  background-color: #fff;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: #98a6ad;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #adb5bd;
}
.daterangepicker .ranges li:hover {
  background-color: #f8f9fa;
}
.daterangepicker .ranges li.active {
  background-color: var(--primary);
}
.daterangepicker td.in-range {
  background-color: #edeff1;
  color: #6c757d;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: #fff;
  color: #6c757d;
  opacity: 0.5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary);
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--primary);
  color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid #fff;
}
.daterangepicker:before {
  border-bottom: 7px solid #e4eaf2;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid #e4eaf2;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background: white;
  border: 1px solid white;
  color: #fff;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid #e4eaf2;
}

.datepicker {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 28px;
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled],
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background-color: var(--primary) !important;
  background-image: none !important;
  color: #fff;
}
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover,
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #eef2f7;
}
.datepicker table tr td.new,
.datepicker table tr td.old,
.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #6c757d;
  opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #eef2f7;
}
.datepicker .datepicker-switch:hover {
  background: none;
}

.datepicker-dropdown:after {
  border-bottom: 6px solid #fff;
}

.datepicker-dropdown:before {
  border-bottom-color: #e4eaf2;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #e4eaf2;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #fff;
}

.bg-dragula {
  background-color: #f7f9fb;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move;
}
.dragula-handle:before {
  content: "\F1DB";
  font-family: "Material Design Icons";
  position: absolute;
}

.dropzone {
  border: 2px dashed #dee2e6;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
  display: none;
}

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: #eef2f7;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f1f3fa;
  border-radius: 3px;
}

.jvectormap-label {
  border: none;
  background: #343a40;
  color: #f1f3fa;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  padding: 5px 8px;
}

.metismenu {
  padding: 0;
}
.metismenu li {
  list-style: none;
}
.metismenu ul {
  padding: 0;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}

.side-nav-second-level li a,
.side-nav-third-level li a,
.side-nav-forth-level li a {
  padding: 8px 30px 8px 65px;
  color: #cedce4;
  display: block;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  font-size: 0.89rem;
}
.side-nav-second-level li a:focus,
.side-nav-second-level li a:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:hover {
  color: #ffffff;
}
.side-nav-second-level li a .menu-arrow,
.side-nav-third-level li a .menu-arrow,
.side-nav-forth-level li a .menu-arrow {
  line-height: 1.3rem;
}

.side-nav-second-level li.active > a,
.side-nav-third-level li.active > a,
.side-nav-forth-level li.active > a {
  color: #ffffff;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a {
  color: #6c757d;
}
body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:focus,
body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:hover,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--primary);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li.active > a {
  color: var(--primary);
}

body[data-layout="detached"] .side-nav .side-nav-second-level li a,
body[data-layout="detached"] .side-nav .side-nav-third-level li a,
body[data-layout="detached"] .side-nav .side-nav-forth-level li a {
  color: #6c757d !important;
}
body[data-layout="detached"] .side-nav .side-nav-second-level li a:focus,
body[data-layout="detached"] .side-nav .side-nav-second-level li a:hover,
body[data-layout="detached"] .side-nav .side-nav-third-level li a:focus,
body[data-layout="detached"] .side-nav .side-nav-third-level li a:hover,
body[data-layout="detached"] .side-nav .side-nav-forth-level li a:focus,
body[data-layout="detached"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--primary) !important;
}

body[data-layout="detached"] .side-nav .side-nav-second-level li.mm-active > a,
body[data-layout="detached"] .side-nav .side-nav-third-level li.mm-active > a,
body[data-layout="detached"] .side-nav .side-nav-forth-level li.mm-active > a {
  color: var(--primary) !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a {
  color: #8391a2;
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:hover,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:hover {
  color: #bccee4;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li.active > a {
  color: #ffffff;
}

.side-nav-third-level li a {
  padding: 8px 30px 8px 80px;
}

.side-nav-forth-level li a {
  padding: 8px 30px 8px 100px;
}

.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: 1px solid #dee2e6;
  height: calc(2.25rem + 2px);
  background-color: #fff;
  outline: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
  color: #6c757d;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #98a6ad transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #98a6ad transparent !important;
  border-width: 0 6px 6px 6px !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: 1px solid #e4eaf2;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff;
}

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: white;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border: 1px solid #dee2e6;
  background-color: #fff;
  color: #6c757d;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: white;
  color: #313a46;
}
.select2-container--default
  .select2-results__option[aria-selected="true"]:hover {
  background-color: var(--primary);
  color: #fff;
}

.select2-container .select2-selection--multiple {
  min-height: calc(2.25rem + 2px);
  border: 1px solid #dee2e6 !important;
  background-color: #fff;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  border: 0;
  color: #6c757d;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--primary);
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 0 7px;
  margin-top: 6px;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
  margin-right: 5px;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #fff;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px;
}

[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

[data-simplebar-lg] .simplebar-scrollbar {
  right: 1px;
  width: 10px;
}

[data-simplebar-primary] .simplebar-scrollbar:before {
  background: var(--primary);
}

.jq-toast-single {
  padding: 15px;
  font-family: "Poppins", sans-serif;
  background-color: var(--primary);
  font-size: 13px;
  line-height: 22px;
}
.jq-toast-single h2 {
  font-family: "Poppins", sans-serif;
}
.jq-toast-single a {
  font-size: 0.9rem;
}
.jq-toast-single a:hover {
  color: #fff;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
}

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: #343a40;
  color: #f1f3fa;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}

.jq-icon-primary {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.jq-icon-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.jq-icon-success {
  background-color: #0acf97;
  color: #fff;
  border-color: #0acf97;
}

.jq-icon-info {
  background-color: #39afd1;
  color: #fff;
  border-color: #39afd1;
}

.jq-icon-warning {
  background-color: #ffbc00;
  color: #fff;
  border-color: #ffbc00;
}

.jq-icon-danger {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-light {
  background-color: #eef2f7;
  color: #fff;
  border-color: #eef2f7;
}

.jq-icon-dark {
  background-color: #313a46;
  color: #fff;
  border-color: #313a46;
}

.jq-icon-error {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}

.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 50%;
  border: 0;
  outline: none !important;
}

.bootstrap-timepicker-widget table td a {
  color: #6c757d;
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--primary);
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid #e4eaf2;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #e4eaf2;
}

.note-editor.note-frame {
  border: 1px solid #dee2e6;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
}
.note-editor.note-frame .note-statusbar {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
.note-editor.note-frame .note-editable {
  border: none;
  background-color: #fff !important;
  color: #6c757d !important;
}

.note-status-output {
  display: none;
}

.note-placeholder {
  color: #adb5bd;
}

.note-toolbar {
  padding: 3px 3px 8px 8px !important;
}

.note-air-popover {
  top: 0px !important;
}

.note-editable {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff !important;
  padding: 0.45rem 0.9rem !important;
  color: #6c757d !important;
}
.note-editable p:last-of-type {
  margin-bottom: 0;
}

.note-btn-group .btn-light {
  background-color: #edeff1;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #edeff1;
  color: #343a40;
}

.note-popover .popover-content,
.card-header.note-toolbar {
  background: #fff;
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
  background-color: var(--primary);
}

.note-editor.note-airframe .note-placeholder,
.note-editor.note-frame .note-placeholder {
  padding-left: 0.9rem;
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview,
.editor-preview-side {
  background: #dee2e6;
}

.editor-preview-active {
  background: #e5e8eb;
}

.editor-toolbar {
  border: 1px solid #dee2e6;
  border-bottom: 0;
}
.editor-toolbar a {
  color: #6c757d !important;
}
.editor-toolbar a:hover,
.editor-toolbar a.active {
  background-color: transparent;
  color: var(--primary) !important;
  border-color: transparent;
}
.editor-toolbar i.separator {
  display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: transparent;
}

.CodeMirror {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #6c757d;
  min-height: 100px;
}

.CodeMirror-cursor {
  border-left: 1px solid #343a40;
}

.editor-statusbar {
  color: #343a40;
}

.twitter-typeahead {
  display: inherit !important;
}

.tt-query,
.tt-hint {
  line-height: 30px;
  outline: none;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #6c757d;
  background: #fff !important;
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  border: 1px solid #e4eaf2;
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion:hover,
.tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: var(--primary);
}
.tt-suggestion p {
  margin: 0;
}

.tt-highlight {
  font-family: "Poppins", sans-serif;
}

.typeahead-empty-message {
  padding: 5px 10px;
  color: #fa5c7c;
}

.league-name {
  padding: 3px 20px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important;
}

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
}

.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.rateit .rateit-range * {
  display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}
.rateit .rateit-hover {
  color: #ffbc00;
}
.rateit .rateit-hover-rtl {
  background-position: right -32px;
}
.rateit .rateit-selected {
  color: #fa5c7c;
}
.rateit .rateit-selected-rtl {
  background-position: right -16px;
}
.rateit .rateit-preset {
  color: #fd7e14;
}
.rateit button.rateit-reset {
  width: 16px;
  height: 16px;
  float: left;
  outline: none;
  border: none;
  padding: 0;
}
.rateit .rateit-reset span {
  display: none;
}
.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  height: 16px;
  outline: none;
}
.rateit.rateit-font .rateit-reset {
  width: 0.6em;
  height: 0.6em;
  margin-right: 5px;
  margin-top: 5px;
  background: #dee2e6;
  border-radius: 50%;
  position: relative;
}
.rateit.rateit-font .rateit-reset span {
  display: block;
  height: calc(50% - 0.045em);
  top: 2px;
  position: absolute;
  border-bottom: 2px solid #6c757d;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.rateit.rateit-font .rateit-reset:hover,
.rateit.rateit-font button.rateit-reset:focus {
  background: #fa5c7c;
}
.rateit.rateit-font .rateit-reset:hover span,
.rateit.rateit-font button.rateit-reset:focus span {
  border-color: #fff;
}

.rateit-mdi {
  font-family: "Material Design Icons";
}

.rateit-font {
  font-size: 24px;
  line-height: 1em;
}
.rateit-font .rateit-range {
  background: none;
  height: auto;
}
.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
}
.rateit-font .rateit-empty {
  color: #ced4da;
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 12px;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #6c757d;
}
.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #6c757d;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #6c757d;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}
.irs--flat.irs-with-grid {
  height: 60px;
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e9ecef;
  border-radius: 4px;
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: var(--primary);
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e9ecef;
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #515df3;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #6c757d;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e9ecef;
  border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: var(--primary);
  border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--primary);
}
.irs--flat .irs-grid-pol {
  background-color: #e9ecef;
}
.irs--flat .irs-grid-text {
  color: #6c757d;
}

.gantt .bar-progress {
  fill: var(--primary);
}

.gantt .bar-wrapper:hover .bar-progress {
  fill: #8a92f7;
}

.gantt-container .popup-wrapper .pointer {
  display: none;
}

.gantt .bar,
.gantt .bar-wrapper:hover .bar {
  fill: #e9ecef;
}

.gantt .bar-label,
.gantt .bar-label.big {
  fill: #6c757d;
}

.gantt .grid-header {
  fill: #f1f3fa;
  stroke: #f1f3fa;
}

.gantt .grid-row:nth-child(even) {
  fill: #f1f3fa;
}

.gantt .grid-row {
  fill: transparent;
}

.gantt .lower-text,
.gantt .upper-text {
  fill: #6c757d;
}

.gantt .row-line {
  stroke: #eef2f7;
}

.gantt .today-highlight {
  fill: #eef2f7;
}
