.image__uploader-drag-browse {
  display: flex;
  padding: 1rem;
  width: 100%;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: dashed;
  border-color: #333333;
}

.image__uploader-drag-browse a {
  color: #333333;
}

.image__uploader-preview-container {
  height: auto;
  overflow: auto;
  margin-top: 0.5rem;
  position: relative;
  bottom: 15px;
}

.image__uploader-preview-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: auto;
  padding: 0 1rem 0 1rem;
  margin-bottom: 1rem;
  background: white;
  border: 1px solid #f1f1f1;
  transition: all 300ms cubic-bezier(0.335, 0.01, 0.03, 1.36);
}

.image__uploader-preview-result {
  display: flex;
  align-items: center;
}

.image__uploader-preview-result img {
  width: 90px;
  height: 70px;
  object-fit: contain;
}

.image__uploader-preview-text {
  margin-left: 1rem;
  margin-top: 0;
}

.image__uploader-preview-text h6 {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.image__uploader-preview-text p {
  margin-top: 0;
  margin-bottom: 0;
}

.image__uploader-action-button {
  width: 35px;
  height: 35px;
  border: 0;
  cursor: pointer;
  margin-left: 0.25rem;
  border-radius: 50%;
}

.image__uploader-action-button .action-button-edit {
  background-color: yellow;
}

.image__uploader-action-button .action-button-delete {
  background-color: red;
}

.image__uploader-action-button:hover {
  opacity: 0.7;
}

.image__uploader-action-button svg {
  width: 17px !important;
  height: 17px !important;
}

.image__uploader-loader {
  width: 30px;
  height: 30px;
  margin: 30px auto;
  display: block;
  background: transparent;
  border-top: 4px solid #333333;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* MODAL CROP */

.image__uploader-modal-crop-overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image__uploader-modal-crop-overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.image__uploader-modal-crop-overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.image__uploader-modal-crop-content-base {
  border-radius: 10px;
  position: relative;
  /*
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    */
  margin: 0 auto;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0%;
  width: 0%;
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.image__uploader-modal-crop-content-after {
  width: 100%;
  height: 100%;
  background-color: white;
}

.image__uploader-modal-crop-content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}

.image__uploader-modal-crop-image {
  height: 500px;
}

.image__uploader-action-button.action-button-save {
  position: absolute;
  top: 10px;
  right: 50px;
}

.image__uploader-action-button.action-button-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.image__uploader-clear-all {
  position: relative;
  /* left: 375px; */
  text-align: right;
  width: 100%;
  top: 5px;
  text-decoration: underline;
  font-size: 0.95rem;
}

.image__uploader-clear-all:hover {
  cursor: pointer;
}
