.form-check {
  display: block;
  margin-bottom: 0;
  min-height: 1.625rem;
  padding-left: 1.5em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-label {
  margin-top: 2px !important;
}

.form-check-label.form-check-label-bold {
  font-weight: bold !important;
}

.form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color-adjust: exact;
  height: 1em;
  margin-top: 0.3125em;
  vertical-align: top;
  width: 1em;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  width: 15px;
  height: 15px;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #13a5a5;
  box-shadow: 0 0 0 3px rgba(19, 165, 165, 0.25);
  outline: 0;
}
.form-check-input:checked {
  background-color: #13a5a5;
  border-color: #13a5a5;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23FFF'/%3E%3C/svg%3E");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #13a5a5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #13a5a5;
}
.form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 3.375em;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2.875em;
  margin-left: -3.375em;
  transition: background-position 0.15s ease-in-out;
  width: 2.875em;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%235C60F5'/%3E%3C/svg%3E");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
  background-position: 100%;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.form-switch {
  min-height: 1.5rem;
}
.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}
.form-switch > .form-check-label {
  margin-top: calc(0.75rem - 0.8125em);
}
.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}
.form-item-check {
  clip: rect(0, 0, 0, 0);
  display: none;
  pointer-events: none;
  position: absolute;
}
.form-item-check:checked + .form-item .form-item-click,
.form-item-check:hover + .form-item .form-item-click {
  border-color: #5c60f5;
}
.form-item-check:disabled + .form-item .form-item-click,
.form-item-check[disabled] + .form-item .form-item-click {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
