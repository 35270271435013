.despin-auth-container {
  height: 100vh;
}

.despin-auth-image {
  background-image: url("https://cdn.pixabay.com/photo/2020/02/01/22/10/house-4811590__340.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #dcf8f4;
}

.despin-auth-wrapper {
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.despin-auth-wrapper h3 {
  text-align: left !important;
}

.despin-auth-wrapper form {
  width: 100%;
}

@media (max-width: 670px) {
  .despin-auth-image {
    display: none;
  }
}
