.btn-neutral {
  background-color: #fff;
  border-color: #e7eaf0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #16192c;
}
.btn-check:focus + .btn-neutral,
.btn-neutral:focus,
.btn-neutral:hover {
  background-color: #e7eaf0;
  border-color: #e7eaf0;
  color: #16192c;
}
.btn-check:focus + .btn-neutral,
.btn-neutral:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-check:active + .btn-neutral,
.btn-check:checked + .btn-neutral,
.btn-neutral.active,
.btn-neutral:active,
.show > .btn-neutral.dropdown-toggle {
  background-color: #fff;
  border-color: #e9ecf2;
  color: #000;
}
.btn-check:active + .btn-neutral:focus,
.btn-check:checked + .btn-neutral:focus,
.btn-neutral.active:focus,
.btn-neutral:active:focus,
.show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-neutral.disabled,
.btn-neutral:disabled {
  background-color: #fff;
  border-color: #e7eaf0;
  color: #000;
}
.btn-square {
  align-items: center;
  display: inline-flex;
  height: 2.875rem;
  justify-content: center;
  padding: 0;
  width: 2.875rem;
}
.btn-square.btn-xs {
  height: 1.75rem;
  width: 1.75rem;
}
.btn-group-sm > .btn-square.btn,
.btn-square.btn-sm {
  height: 2.25rem;
  width: 2.25rem;
}
.btn-group-lg > .btn-square.btn,
.btn-square.btn-lg {
  height: 3.5rem;
  width: 3.5rem;
}
.btn-square.btn-xl {
  height: 6rem;
  width: 6rem;
}
.btn-square.btn-2xl {
  height: 7.5rem;
  width: 7.5rem;
}
